/* Tabs */
.po-tabs {
    margin: 20px 0;
    width: 800px;
  }
  
  .po-tab {
    background-color: transparent;
    border: none;
    font-size: 1.2rem;
    padding: 10px 20px;
    cursor: pointer;
    color: #555;
    border-bottom: 2px solid transparent;
    transition: all 0.3s ease;
  }

 
  
  .po-tab.active {
    color: #000;
    font-weight: 400;
    border-bottom: 2px solid #d36161;
  }
  
  .po-tab:hover {
    color: #d36161;
  }
  
  /* Tab Content */
  .po-tab-content {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
   width: 800px;
  }

  .uniquepointstr{
    padding-bottom: 10px;
    border-bottom: solid 1px black;
    width: 100%;
  }
  

  

  
  .po-features {
    gap: 10px;
  }
  
  .po-feature-icon {
    height: 20px;
    color: #d36161;
  }
  
  .po-feature-text {
    font-size: 1rem;
    color: #333;
  }
  
  .po-cta {
    margin-top: 20px;
  }
  
  .po-button {
    background-color: #d36161;
    color: #fff;
    padding: 10px 20px;
    border: none;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .po-button:hover {
    background-color: #b35050;
  }
  
  .po-cta-link {
    margin-left: 15px;
    font-size: 1rem;
    color: #d36161;
    text-decoration: none;
  }
  
  .po-cta-link:hover {
    text-decoration: underline;
  }
  
  .firstimgtr{
    height: 230px;
    width: 420px;
  }
   
  .secondimgtr{
    height: 250px;
    width: 200px;
  }


  .fade-enter {
    opacity: 0;
    transform: translateY(-10px);
  }
  
  .fade-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms;
  }
  
  .fade-exit {
    opacity: 1;
    transform: translateY(0);
  }
  
  .fade-exit-active {
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 300ms, transform 300ms;
  }

  @media (max-width: 1100px) {
    .firstimgtr{
      height: 200px;
      width: 320px;
    }
  }
  

  @media (max-width: 768px) {
    .po-tab-content {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
     width: 95vw;
    }

    .mdimgcontainer{
      width: 250px;
    }

    .firstimgtr{
      height: 230px;
      width: 100%;
    }
     


    .po-features {
      gap: 30px;
      width: 90%;
    }

    .po-tab {
      font-size: 15px;
      padding: 10px 10px;
      cursor: pointer;
      color: #555;
      border-bottom: 2px solid transparent;
      transition: all 0.3s ease;
    }
  }