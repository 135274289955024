.blogs-home-tabs {
    margin-top: 50px;
  }
  
  .blogs-tabs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 600px;
  }
  
  .blogs-tab-btn {
    background-color: #f0f0f0;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
  }
  

  
  .blogs-tab-btn.selected {
    background-color: #e15252;
    color: white;
  }
  
  
  .blogs-cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .blogs-card {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    width: calc(33.333% - 20px); /* 3 cards per row */
    margin-bottom: 20px;
    height: 500px;
  }
  
  .blogs-card:hover {
    transform: translateY(-10px);
  }
  
  .blogs-card-img {
    width: 100%;
    height: 40%;
    object-fit: cover;
  }
  
  .blogs-card-content {
    padding: 15px;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensure the button and meta stay at the bottom */
  }
  
  
  .blogs-card-category {
    font-size: 0.875rem;
    color: #e15252;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  
  .blogs-card-title {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 10px;
    min-height: 50px;
  }
  
  .blogs-card-summary {
    flex-grow: 1;
    font-size: 1rem;
    color: #555;
    margin-bottom: 10px;
  }
  
  .blogs-card-meta {
    font-size: 0.875rem;
    color: #888;
    margin-top: auto; /* Push meta to the bottom */
    margin-bottom: 10px; /* Keep it 10px from bottom */
  }
  
  .blogs-btn-request {
    background-color: #e15252;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px; /* Ensure 10px gap from the bottom */
  }
  
  
  .blogs-btn-request:hover {
    background-color: #cd3434;
  }
  
  .blogs-btn-read-more {
    background-color: #e15252;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    display: block;
  }
  
  .blogs-btn-read-more:hover {
    background-color: #218838;
  }

  .blogs-bottomdata{
    bottom: 20px;
  }



  /* Container styling */
.blogs-dropdown-container {
    position: relative;
    width: 90%; /* Adjust width as needed */
    margin: 1rem auto;
  }
  
  /* Dropdown header */
  .blogs-dropdown-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #ffffff;
    cursor: pointer;
    transition: background-color 0.3s ease;
    color: rgb(0, 0, 0);
    width: 100%;
  }
  
  /* Arrow styling */
  .blogs-dropdown-arrow {
    font-size: 0.8rem;
    transition: transform 0.3s ease;
  }
  
  .blogs-dropdown-arrow.rotate {
    transform: rotate(180deg);
  }
  
  /* Dropdown menu */
  .blogs-dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: #fcfcfc;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    animation: fadeIn 0.3s ease;
    z-index: 10;
  }
  
  /* Dropdown items */
  .blogs-dropdown-item {
    padding: 0.8rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .blogs-dropdown-item:hover {
    background-color: #f1f1f1;
  }



  
  /* Fade-in animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  










  
  @media (max-width: 768px) {
    .blogs-cards-container {
      justify-content: center;
    }
    .blogs-tabs {
      align-items: flex-start;
      width: 90vw;
      flex-wrap: wrap;
    }
  
    .blogs-tab-btn {
      margin-bottom: 10px;
    }
  
    .blogs-card {
      width: calc(100% - 20px); /* 1 card per row on small screens */
    }

    .blogs-home-tabs {
        margin-top: 10px;
      }

      .blogs-card-title {
        font-size: 1.15rem;
        font-weight: bold;
        margin-bottom: 10px !important;
        min-height: 50px;
        padding-bottom: 10px;
      }
      .blogs-card-summary {
        flex-grow: 1;
        font-size: 16px;
        color: #555;
        margin-bottom: 10px;
      }
      .blogs-card-category {
       
        margin-bottom: 7px;
      }
  }
  


  @media (max-width: 366px) {
    .blogs-card-title {
      font-size: 1.15rem;
      font-weight: bold;
      margin-bottom: 10px !important;
      min-height: 70px;
      padding-bottom: 10px;
    }
    .blogs-card {
      height: 550px;
    }
  }
  














  