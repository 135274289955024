/* General container */
.workspacesolutions {
  margin-top: 120px;
  max-width: 1300px;
  width: 100%;
}

/* General container */
.easylinks {
  font-size: 14px;
  color: #999;
}

.workspacepara{
font-size: 15px;
color: #999;
text-align: justify; /* Distribute text evenly across the width */
line-height: 1.6; /* Adjust line height for better readability */
word-break: break-word; /* Prevent words from overflowing */
hyphens: auto; /* Enable word hyphenation for better justification */
}

.custom-link:hover {
  text-decoration: underline;
}

/* Grey Section Background */
.grey-section {
  background-color: rgb(241, 241, 241); 
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.049);
  margin-top: 50px;
}

/* Category (Left side) */
.category-icon {
  display: flex;
}

.icon-img {
  height: 100px; 
  width: 100px;
  margin-top: 20px;
}

.category-title {
  font-size: 24px;
  font-weight: 500;
  margin-top: 30px;
}

/* Solution Cards (Right side) */
.solution-card {
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.151);
  transition: transform 0.3s ease-in-out;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
  gap: 10px;
  height: 250px;
}

.solution-card:hover {
  transform: translateY(-5px);
}

.solution-image {
  height: 85%;
  object-fit: cover;
  width: 50%;
  border-radius: 10px;
}

.solution-content {
  padding: 15px;
  width: 40%;
  text-align: left;
}

.solution-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
  text-align: start;
}

.solution-text,
.solution-price {
  font-size: 14px;
  color: #666;
  margin: 5px 0;
}

.solution-link {
  color: #e15252;
  font-weight: bold;
  text-decoration: none;
  margin-top: 30px;
}

.solution-link:hover {
  text-decoration: underline;
}



/* Responsive Design */
@media (max-width: 768px) {
  .solution-card {
    flex: 1 1 100%; /* Stack vertically */
    flex-direction: column;
  }
  .solution-image {
    height: 100px;
    object-fit: cover;
  
  }
  .solution-content {
    padding: 15px;
    width: 85%;
    text-align: left;
  }
  

  .category-title {
    font-size: 20px;
  }
  .workspacesolutions {
    margin-top: 0px;
    max-width: 1300px;
    width: 100%;
  }
  .solution-link {
    
    margin-top: 10px;
  }
}
@media (700px <= width <= 1000px ) {
  .workspacesolutions {
    margin-top: 0px !important;
  }
}