.walkinbook-container {
    position: absolute;
    height: 100vh;
    overflow: hidden;
  }

  .walkinbookb-close{
    height: 30px;
    border: none;
    color: #e15252;
    font-size: 25px;
    background-color: transparent;
    margin-top: -10px;
  }

  .pop-up-error-text{
    color: #e15252;
    font-weight: 500;
  }
  
  .walkinbook-toggle-btn {
    left: 0%;
    padding: 5px 10px;
    background-color: #e15252;
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 12px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .walkinbook-toggle-btn:hover {
    background-color: #c14040;
  }
  
  .walkinbook-form {
    position: fixed;
    top: 0;
    left: -400px; /* Hidden by default */
    width: 350px;
    height: 100%;
    background-color: #fff;
    box-shadow: 2px 0 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    transition: left 0.5s ease;
    z-index: 1000;
    overflow-y: auto; /* Enable scrolling when content overflows */
  }
  
  .walkinbook-form.open {
    left: 0; /* Slide in when open */
  }
  
  .walkinbook-form h2 {
    margin-bottom: 0px;
    color: #333;
    font-size: 20px;
  }
  
  .walkinbook-input-group {
    margin-bottom: 15px;
  }
  
  .walkinbook-input-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
  }

  /* .walkinbook-input-group option:hover{
      background-color: #555;
  } */
  
  .walkinbook-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .walkinbook-time-selectors {
    display: flex;
    gap: 10px;
  }
  
  .walkinbook-submit-btn {
    width: 100%;
    padding: 10px;
    background-color: #e15252;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .walkinbook-submit-btn:hover {
    background-color: #c14040;
  }
  


  .walkinbook-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4); /* Dim background */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    z-index: 999;
  }
  


  /* Responsive Styles */

/* For Tablets (between 768px and 1024px) */
@media (max-width: 1024px) {
  .walkinbook-form {
    width: 300px; /* Slightly smaller for tablets */
  }

  .walkinbook-toggle-btn {
    font-size: 14px;
    padding: 8px 16px;
  }
}

/* For Mobile Devices (below 768px) */
@media (max-width: 768px) {
  .walkinbook-form {
    width: 85%; /* Full width on mobile */
    left: -100%; /* Hide completely off-screen */
    transition: left 0.4s ease;
  }

  .walkinbook-form.open {
    left: 0; /* Slide in fully on mobile */
  }

  .walkinbook-toggle-btn {
    font-size: 14px;
    padding: 8px 16px;
    top: 10px;
    left: 10px;
  }

  .walkinbookb-close {
    font-size: 22px;
  }

  .walkinbook-input {
    padding: 8px;
    font-size: 13px;
  }

  .walkinbook-submit-btn {
    font-size: 14px;
    padding: 8px;
  }
}

/* For Very Small Screens (below 480px) */
@media (max-width: 480px) {
  .walkinbook-form h2 {
    font-size: 20px;
  }

  .walkinbook-toggle-btn {
    font-size: 12px;
    padding: 6px 12px;
  }

  .walkinbookb-close {
    font-size: 20px;
  }

  .walkinbook-input-group label {
    font-size: 13px;
  }

  .walkinbook-input {
    padding: 6px;
    font-size: 12px;
  }

  .walkinbook-submit-btn {
    font-size: 13px;
    padding: 6px;
  }
}