.blogs-detail-page {
    font-family: Arial, sans-serif;
    margin-top: 130px;
  }
  
  .blogs-banner {
    height: 450px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  
  .blogs-banner-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.623);
    color: white;
    padding: 20px;
  }
  
  .blogs-main-content {
    display: flex;
    gap: 20px;
    margin-top: 20px;
    width: 100%;
    margin-bottom: 60px;
  }
  
.blogs-left{
  width: 75%;
}

.righbox-sticky{
  width: 25%;
}
  

  
  .blogs-author-date {
    font-size: 1rem;
    color: #888;
  }
  

  

  
  .blogs-latest-blogs h3 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .blogs-latest-item {
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid #eee;
    
  }

  .blogs-latest-item:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  }
  
  .blogs-latest-item h4 {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 4px;
  }
  
  .blogs-latest-meta {
    font-size: 0.9rem;
    color: #888;
    margin-bottom: 5px;
  }
  

  
  .blogs-categories {
    margin-top: 30px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
  }
  
  .blogs-category-tabs {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
  
  .blogs-category-link {
    padding: 10px 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
    background-color: #f0f0f0;
    font-weight: bold;
    text-decoration: none;
    color: #333;
  }
  
  .blogs-category-link:hover {
    background-color: #e15252;
    color: white;
  }

  .blogs-left p {
    word-wrap: break-word; /* Ensures long words break properly */
    overflow-wrap: break-word;
    hyphens: auto; /* Adds hyphenation for long words */
    white-space: normal; /* Prevents text from shrinking */
    line-height: 1.6; /* Improves readability */
    text-align: justify;
  }

  .author-social-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }


  /* .blogs-social-links {
    display: flex;
    gap: 15px;
  }
  
  .blogs-social-links a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    background-color: #f1f1f1;
    border-radius: 50%;
    text-decoration: none;
    font-size: 16px;
    transition: background 0.3s ease, color 0.3s ease;
  }
  
  
  .blogs-social-links a:hover {
    background-color: #dfdfdf;
  } */
  
  
  .blogs-social-links {
    display: flex;
    gap: 15px;
  }
  
  
  .blogs-social-links a,
  .blogs-social-links button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    background-color: #f1f1f1;
    border-radius: 50%;
    text-decoration: none;
    font-size: 16px;
    transition: background 0.3s ease, color 0.3s ease;
    border: none;
    cursor: pointer;
  }
  
  /* Ensure SVG Icons are correctly colored */
  .blogs-social-links a svg,
  .blogs-social-links button svg {
    color: #333;
    transition: color 0.3s ease; /* Smooth color transition */
  }
  
  /* Change background on hover */
  .blogs-social-links a:hover,
  .blogs-social-links button:hover {
    background-color: #e15252;
  }
  
  /* Change icon color on hover */
  .blogs-social-links a:hover svg,
  .blogs-social-links button:hover svg {
    color: #fff; /* This ensures the icon turns white */
  }

  .blog-detail-title{
    font-size: 28px !important;
  }

  .blogs-detail-page h2{
    font-size: 25px;
    font-weight: 600;
  }

  .blogs-detail-page h3{
    font-size: 23px;
    font-weight: 500;
  }


  .skeleton-banner {
    width: 100%;
    height: 400px;
    background: #e0e0e0;
    animation: pulse 1.5s infinite;
  }
  
  .skeleton-title {
    width: 80%;
    height: 24px;
    background: #e0e0e0;
    margin-bottom: 10px;
    animation: pulse 1.5s infinite;
  }
  
  .skeleton-meta {
    width: 50%;
    height: 16px;
    background: #e0e0e0;
    margin-bottom: 20px;
    animation: pulse 1.5s infinite;
  }

  .skeleton-line{
    width: 90%;
    height: 24px;
    background: #e0e0e0;
    margin-bottom: 10px;
    animation: pulse 1.5s infinite;
  }

  .skeleton-sidebar{
    width: 100%;
    height: 600px;
    background: #e0e0e0;
    margin-bottom: 10px;
    animation: pulse 1.5s infinite;
  }
  
  @keyframes pulse {
    0% { opacity: 0.6; }
    50% { opacity: 1; }
    100% { opacity: 0.6; }
  }


  .latest-blog-summery {
    margin-bottom: 10px;
    font-size: 14px;
    color: rgba(40, 40, 40, 0.745);
    overflow: hidden; 
    text-overflow: ellipsis; 
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word; /* ✅ Ensures long words break properly */
    white-space: normal; /* ✅ Allows wrapping */
    max-width: 100%; /* ✅ Ensures it doesn't exceed container */
  }


  .latest-blog-catgerory{
    font-size: 14px;
    margin-bottom: 6px;
    color: gray;
  }

  .latest-blog-image{
    height: 130px;
    width: 100%;
    object-fit: cover;
    margin-bottom: 10px;
  }

.latest-content-box{
  padding: 10px;
}

  @media (max-width: 1024px) {
    .author-social-container{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 20px;
      align-items: start;
      gap: 10px;
    }
    .skeleton-line{
      width: 100%;
    }
    .blogs-latest-blogs {
      max-height: unset; 
      overflow-y: visible;
    }

    .blog-detail-title{
      font-size: 22px !important;
    }


    .blogs-main-content {
      flex-direction: column;
      margin-top: 20px;
      width: 100%;
      margin-bottom: 60px;
    }
    .blogs-left{
      width: 100%;
    }
    
    .righbox-sticky{
      width: 100%;
    }
    .blogs-detail-page {
      margin-top: 10px;
    }
    .blogs-banner {
      height: 250px;
      width: 100%;
    }
    .blogs-banner-overlay {
      background: rgba(0, 0, 0, 0.5);
      color: white;
      padding: 10px;
    }
    .blogs-banner-overlay h3{
      font-size: 18px;
    }
    .blogs-detail-page h2{
      font-size: 18px;
    }
    .blogs-main-content{
      padding: 5px;
      margin-bottom: 10px;
    }

    .blogs-left p {
      word-wrap: break-word; /* Ensures long words break properly */
      overflow-wrap: break-word;
      hyphens: auto; /* Adds hyphenation for long words */
      white-space: normal; /* Prevents text from shrinking */
      line-height: 1.6; /* Improves readability */
      text-align: justify;
    }
    

  }


