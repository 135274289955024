


.faq-header{
  font-size: 22px;
  border-bottom: 1px dotted #ccc;
  padding: 24px;
  text-align: center;
  font-weight: 500;
}

.faq-content {
  margin: 0 auto;
}

.faq-question {
  padding: 10px 0;
  border: 1px solid #cbcbcb;
  width: 98%;
  margin: 6px;
  border-radius: 5px;
}

/* .panel-title {
  font-size: 20px;
  width: 100%;
  position: relative;
  margin: 0;
  padding: 10px 10px 0 48px;
  display: block;
  cursor: pointer;
  margin-left: 20px;
}

.panel-content {
  font-size: 16px;
  padding: 0px 14px;
  margin: 0 40px;
  height: 0;
  overflow: hidden;
  z-index: -1;
  position: relative;
  opacity: 0;
  -webkit-transition: .2s ease;
  -moz-transition: .2s ease;
  -o-transition: .2s ease;
  transition: .2s ease;
}

.panel:checked ~ .panel-content{
  height: auto;
  opacity: 1;
  padding: 14px;
}

.plus {
  position: absolute;
  margin-left: 20px;
  margin-top: 4px;
  z-index: 5;
  font-size: 32px;
  line-height: 100%;
  -webkit-user-select: none;    
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-transition: .2s ease;
  -moz-transition: .2s ease;
  -o-transition: .2s ease;
  transition: .2s ease;
}

.panel:checked ~ .plus {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.panel {
  display: none;
} */


.panel-title {
  font-size: 18px;
  width: 100%;
  position: relative;
  padding: 5px 10px 0 28px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
}

.plus {
  position: absolute;
  right:  15px;
  font-size: 32px;
  line-height: 100%;
  transform-origin: center;
  transition: transform 0.2s ease;
}

.panel:checked + .panel-title .plus {
  transform: rotate(45deg);
}

.panel-content {
  font-size: 13px;
  padding: 0px 14px;
  margin: 0 10px;
  height: 0;
  overflow: hidden;
  opacity: 0;
  transition: height 0.2s ease, opacity 0.2s ease, padding 0.2s ease;
}

.panel:checked ~ .panel-content {
  height: auto;
  opacity: 1;
  padding: 14px;
}

.panel {
  display: none;
}

.alignitemsfqmb{
  align-items: start;
}



@media (max-width: 768px) {

  .alignitemsfqmb{
    align-items: center;
    width: 99vw;
  }
  .panel-title {
    font-size: 17px;
    width: 94%;
    position: relative;
    margin: 0;
    padding: 10px 10px 0 20px;
    display: block;
    cursor: pointer;
  }

  .faq-question {
    padding: 10px 0;
    border: 1px solid #cbcbcb;
    width: 95%;
    margin: 6px;
    border-radius: 5px;
  }


  .plus {
    position: absolute;
    right:  -6px;
    margin-left: 0px;
    margin-top: 4px;
    z-index: 5;
    font-size: 25px;
    line-height: 100%;
    -webkit-user-select: none;    
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-transition: .2s ease;
    -moz-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease;
  }

  .faq-header{
    font-size: 22px;
    border-bottom: 1px dotted #ccc;
    padding: 24px;
  }
}