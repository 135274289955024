.bgslide{
    background-color: #F8EDE1;
    height: 100vh;
    width: 100vw;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
}

.mbbggg{
    background-color: #F8EDE1;
    height: 100%;
}


.slide1heading{
    font-size: 55px;
    font-weight: 350;
   margin-left: -76px;
   margin-top: 80px;
   font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
}

.testred{
    width: 500px;
    height: 500px;
}


.kuch{
    color: #D36161;
    font-weight: 600;

}

.img1{
    height: 370px;
   
}

.img2{
    height: 340px;
    width: 200px;
   margin-bottom: -30px;
}


.mbslide1heading{
    height: 100px;
    width: 70vw;
    background-image: url('../assests/ffrgrg.avif');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    
}

.mbslide1heading h1{
    font-size: 25px;
    font-weight: 400;
}

.img3{
    height: 180px;
    width: 160px;
}


.kuchmb{
    color: #D36161;
    font-weight: 600;
    text-align: center;
}

.slide101{
    height: 350px;
    width: 180px;
}
.slide102{
    height: 350px;
    width: 180px;
}

.slide1para{
    font-weight: 350;

}

.containerheight{
    height: 90vh;
}


.slide1mb{
    height: 100%;
    width: 90%;
}

.mbpt{
  padding-top: 70px;
}

.picsmb{
    margin-top: 30px;
}

.silde1mgbg{
    background-image: url('../assests/silde1mdbg.avif');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
}

@media (300px <= width <= 700px ) {
    .bgfulllength{
        height: 100vh;
    }
    .moblievbox{
        height: 100vh;
    }

    .mbslide1heading{
        margin-top: 30px;
        
    }
    
    .mbslide1heading h1{
        font-size: 25px;
        font-weight: 400;
    }

    .secondboxsz{
        height: 30vh;
    }
    .imageboxx{
        height: 20vh;
    }
    .testred{
        width: 100vw;
        height: 40vh;
        padding-left: 0px;

    }
    .allpagemargintop{
        margin-top: 20px;
        margin-bottom: 25px;
    }
}



@media (min-width: 1243px) and (max-width: 1400px) {
    .slide1para p{
        font-size: 20px;
    }
    .img2{
        height: 340px;
    }

}

@media (min-width: 1400px) and (max-width: 1800px) {
    .slide1para p{
        font-size: 25px;
        
    }
    .img1{
        height: 360px;
       
    }
    .img2{
        height: 320px;
    }
    .slide101{
        height: 350px;
        width: 200px;
    }
    .slide102{
        height: 350px;
        width: 200px;
    }
    .containerheight{
        height: 92vh;
    }
}

@media (min-width: 1800px) and (max-width: 3000px) {
    .slide1para p{
        font-size: 28px;
        padding-top: 40px;  
    }
    .img1{
        height: 400px;
       
    }
    .img2{
        height: 350px;
    }
    .slide101{
        height: 430px;
        width: 220px;
    }
    .slide102{
        height: 430px;
        width: 220px;
    }
    .containerheight{
    height: 93vh;
}
  
}
