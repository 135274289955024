.vo-container {
  height: 200px;
width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vo-slider {
  width: 90%;
  height: 150px;
  overflow: hidden;
  position: relative;
}

.vo-slide-track {
  display: flex;
  transition: transform 0.5s ease-in-out;
  height: 100%;
  align-items: center;
  gap: 10px;
}

.vo-slide {
  height: 150px;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border: solid 1px rgba(190, 190, 190, 0.536);
  border-radius: 10px;
}

.vo-slide h3 {
  font-size: 17px;
  color: rgba(128, 128, 128, 0.64);
}

.vo-slide button {
  color: white;
  border: none;
  padding: 4px;
  width: 150px;
  font-size: 12px;
  border-radius: 10px;
  background-color: #e15252;
}

.vo-slide img {
  height: 100%;
  width: 200px;
  object-fit: contain;
}

.vo-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #ffffff;
  color: rgb(88, 88, 88);
  font-size: 30px;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: solid 1px rgba(190, 190, 190, 0.536);
}

.vo-btn.left-btn {
  left: 10px;
}

.vo-btn.right-btn {
  right: 10px;
}

.vo-btn:hover {
  background-color: #c42c2c;
}

.vo-heading {
  font-size: 40px;
  color: rgb(30, 30, 30);
}

@media (max-width: 768px) {
  .vo-heading {
    font-size: 35px;
  }

  .vo-container {
    height: 150px;
    padding-bottom: 15px;
  }



  .vo-slide-track {
    justify-content: flex-start; /* Align slides to the start */
  }

  .vo-slide {
    height: 95%;
    width: 250px; /* Adjust to fit the screen width */
    margin-right: 0; /* Remove margin for cleaner alignment */
    flex: 0 0 100%; /* Ensure the card takes the full width */
  }

  .vo-slide h1 {
    font-size: 13px;
    color: rgba(128, 128, 128, 0.64);
  }
  
  .vo-slide button {
    color: white;
    border: none;
    padding: 5px;
    width: 100px;
    font-size: 10px;
    border-radius: 10px;
    background-color: #e15252;
  }
  .vo-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #ffffff;
    color: rgb(88, 88, 88);
    font-size: 20px;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 1000;
    border: solid 1px rgba(190, 190, 190, 0.536);
  }

  .vo-slide img {
    height: 100%;
    width: 150px;
    object-fit: contain;
  }

  .vo-heading {
    font-size: 25px;
    color: rgb(30, 30, 30);
  }

  .vo-btn.left-btn {
    left: 1px;
  }
  
  .vo-btn.right-btn {
    right: 1px;
  }
}
