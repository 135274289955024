/* Container Styling */
.privacy-policy {
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .privacy-policy .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  /* Heading */
  .privacy-policy h4 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    /* text-align: center; */
    color: #333;
  }
  
  /* Table Styling */
  .table-responsive {
    overflow-x: auto;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
  }
  
  /* Table Header */
  .table th,
  .table td {
    padding: 12px;
    text-align: center;
    border: 1px solid #ddd;
    font-size: 0.9rem;
  }
  
  /* Table Header */
  .table th {
    background-color: #f1f1f1;
    font-weight: bold;
  }
  
  /* Links */
  .in-cell-link {
    color: #e15252;
    text-decoration: none;
    font-weight: bold;
  }
  
  .in-cell-link:hover {
    text-decoration: underline;
  }
  
  /* Alternating Row Colors */
  .table tbody tr:nth-child(even) {
    background-color: #f8f8f8;
  }
  
  /* Empty Cells */
  .table td:empty {
    background-color: #fafafa;
    color: #aaa;
    font-style: italic;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .privacy-policy h4 {
      font-size: 1.2rem;
    }
  
    .table th,
    .table td {
      padding: 8px;
      font-size: 0.8rem;
    }
  }
  