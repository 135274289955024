/* Base Container */
.mobilenewnav-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;
  margin-bottom: -2px;
}

/* Navigation Bar */
.mobilenewnav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 101;
}

/* .mobilenewnav-menu-btn {
  background-color: #e15252;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-top: -35px;
} */

.mobilenewnav-menu-btn {
  width: 50px !important;
  height: 50px !important;
  min-width: 50px !important;
  min-height: 50px !important;
  max-width: 50px !important;
  max-height: 50px !important;
  border-radius: 50% !important;
  -webkit-appearance: none; /* Remove Safari styles */
  appearance: none;
  display: flex !important;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: visible;
  margin-top: -35px;
  background-color: #e15252;
  border: none;
  color: white;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.mobilenewnav-menu-btn svg {
  font-size: 30px !important;
  line-height: normal !important;
  vertical-align: middle;
}


.mobilenewnav-menu-btn.active {
  background-color: #333;
}

/* Icon Container */
.mobilenewnav-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: 9px;
}

/* Icon Items */
.mobilenewnav-icon-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
  font-size: 14px;
  color: black;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  padding: 5px 5px;
  border-radius: 5px;
}

.mobilenewnav-icon-item svg {
  font-size: 22px;
}

/* Active Tab */
.mobilenewnav-icon-item.active {
  background-color: #e15252;
  color: white;
}

.mbiconname{
  margin-bottom: 0px;
  font-size: 12px;
}

/* Home Button */
.homebtnnav {
  color: #e15252;
}

.homebtnnavactive {
  color: white;
}

/* Dropdown Container */
.mobilenewnav-dropdown {
  position: absolute;
  bottom: 60px;
  width: 100%;
  background-color: white;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  transform: translateY(150%);
  transition: transform 0.4s ease-in-out;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  z-index: 99;
  padding: 15px 0;
  padding-bottom: 25px;
}

/* Dropdown Open */
.mobilenewnav-dropdown.open {
  transform: translateY(0);
}

/* Dropdown Icon Wrapper */
.mobilenewnav-dropdown-icons {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

/* Individual Icon Item */
.mobilenewnav-dropdown-icon-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: black;
  font-size: 14px;
  transition: color 0.3s ease-in-out;
  width: 30%;
}

.mobilenewnav-dropdown-icon-item:hover {
  color: #e15252;
}

/* Icon Style */
.dropdown-icon {
  font-size: 26px;
  margin-bottom: 5px;
  color: black;
  transition: color 0.3s ease-in-out;
}

/* Icon Hover Effect */
.mobilenewnav-dropdown-icon-item:hover .dropdown-icon {
  color: #e15252;
}
