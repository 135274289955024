/* Container */
.maylike-container {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  
  /* Header Section */
  .maylike-header {
    display: flex;
    align-items: center;
    color: #e15252;
    border-radius: 5px;
    font-weight: bold;
    font-size: 30px;
  }
  
  .maylike-subheading {
    font-size: 0.9rem;
    margin-left: 20px;
  }
  
  /* Articles Grid */
  .maylike-articles {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Space between cards */
    margin-top: 20px;
  }
  
  /* Individual Article Card */
  .maylike-article-card {
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 5px;
    width: calc(25% - 20px); /* 4 columns on large screens */
    min-width: 250px;
    display: flex;
    flex-direction: column;
    transition: box-shadow 0.3s ease;
  }
  
  .maylike-article-card:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  }
  
  /* Image Wrapper */
  .maylike-image-wrapper {
    position: relative;
    width: 100%;
    height: 140px; /* Adjust as needed */
    overflow: hidden;
  }
  
  .maylike-image-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* Category Label */
  .maylike-category {
    position: absolute;
    bottom: 5px;
    left: 5px;
    background-color: #222222; /* Adjust category color as needed */
    color: #fff;
    padding: 2px 8px;
    border-radius: 3px;
    font-size: 10px;
    letter-spacing: 0.8px;
  }
  
  /* Article Title & Meta */
  .maylike-article-card h3 {
    font-size: 1rem;
    margin: 10px;
    line-height: 1.4;
    flex-grow: 1; /* Pushes meta to the bottom if card is tall */
  }
  
  .maylike-meta {
    font-size: 0.8rem;
    color: #888;
    margin: 0 10px 10px;
  }
  
  /* Responsive Breakpoints */
  @media (max-width: 992px) {
    .maylike-article-card {
      width: calc(33.333% - 20px); /* 3 columns */
    }

  }
  
  @media (max-width: 768px) {
    .maylike-header {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
      /* Header Section */
  .maylike-header {
    font-size: 20px;
  }
    .maylike-article-card {
      width: calc(50% - 20px); /* 2 columns */
    }
  }
  
  @media (max-width: 480px) {
    .maylike-articles {
      justify-content: center;
    }
    .maylike-article-card {
      width: 100%; /* 1 column */
      max-width: 350px;
    }
  }
  