
.header {
    position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background: transparent;
      padding: 0 1.5rem;
      height: 6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 3rem;
      z-index: 999;
      transition: position 0.3s ease, background-color 0.3s ease;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      width: 100vw;
  }
  
  .header.sticky {
    position: fixed; 
    background: white;
    color: black;
  }
  
  
  
  .logo {
      height: 65px;
      width: 160px;
      object-fit: contain;
  }
  
  .navbar {
      display: flex;
      align-items: center;
      height: 100%;
    margin-left: 30px;
    min-height: 60px;
  }
  
  .navbar__menu {
      display: flex;
      list-style: none;
      padding: 0;
      margin: 0;
  }
  
  .navbar__menu li {
      position: relative;
      padding: 5px 5px;
      cursor: pointer;
  }
  
  .navbar__link {
      display: flex;
      align-items: center;
        color: #6d6d6d;
      text-decoration: none;
      font-size: 16px;
      margin-top: 8px;
      font-weight: 550;
  }
  
  
  .dropdown {
    min-height: 100px; 
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    color: #333;
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 999;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    transition: opacity 0.3s ease, transform 0.9s ease;
    display: inline-flex; 
    flex-direction: column;
  }
  
  .dropdown.show {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    padding: 0px;
  }
  
  .dropdown__item {
    padding-bottom: 10px !important;
    color: #333;
    cursor: pointer;
    font-size: 15px;

    white-space: nowrap; 
  }
  
  .dropdown__item:not(:last-child)::after {
    content: "";
    display: block;
    height: 1px;
    width: 90%;
    margin-top: 4px;
    background-color: rgba(128, 128, 128, 0.269);
  }
  
  .dropdown li{
  list-style: none;
  }
  
  
  
  
  .dropdown__item.show {
      opacity: 1; 
  }
  
  .dropdown__item:hover {
      color: #e15252;
      font-weight: 500;
  }
  
  
  .navbar__link:hover{
      color: #e15252;
  }
  
  .btnenqury {
      border-radius: 8px;
      padding: 5px 15px;
      border: none;
      background-color: #e15252;
      color: white;
      font-weight: 500;
      transition: transform 0.1s ease, box-shadow 0.1s ease; 
  }
  
  .btnenqury:hover {
      transform: scale(1.1); /* Scales the button to 10% larger */
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Adds a shadow for depth */
  }
  
  .custom-link {
    text-decoration: none;
    color: inherit; /* Adjust as per your need */
  }
  
  
  .nav {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: all 0.3s;
      margin-top: -8px;
  }
  
  .nav a {
      font-weight: 400;
      line-height: 1.5;
      color: white;
      text-decoration: none;
      position: relative; /* Make sure the ::after element is relative to this element */
  }
  
  .nav a::after {
      content: "";
      display: block;
      height: 3px;
      width: 0;
      background: #e15252;
      transition: width 0.5s;
      position: absolute;
      bottom: -7px; /* Position it just below the text */
      left: 0;
  }
  
  .nav a:hover::after {
      width: 100%; /* Animate to full width on hover */
  }

  .navnumber{
    font-size: 16px;
  }

  .navnumbertext{
    font-size: 12px;
  }










  /* From Uiverse.io by mahiatlinux */
.mb-altfbtn {
  /* Variables */
  --button_radius: 0.75em;
  --button_color: #1a1a1a;
  --button_outline_color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  border-radius: var(--button_radius);
  background: var(--button_outline_color);
  padding: 0px;
}

.mb-altfbtn-top {
  display: block;
  box-sizing: border-box;
  border: 2px solid var(--button_outline_color);
  border-radius: var(--button_radius);
  padding: 0.2em 1.2em;
  background: var(--button_color);
  color: var(--button_outline_color);
  transform: translateY(-0.2em);
  transition: transform 0.1s ease;
}

.mb-altfbtn:hover .mb-altfbtn-top {
  /* Pull the button upwards when hovered */
  transform: translateY(-0.33em);
}

.mb-altfbtn:active .mb-altfbtn-top {
  /* Push the button downwards when pressed */
  transform: translateY(0);
}






  @media (max-width: 1510px) {
    .navbar__menu {
      display: flex;
      list-style: none;
      padding: 0;
      margin: 0;
  }
  .navbar {
    display: flex;
    align-items: center;
    height: 100%;
  margin-left: 0px;
}
  
  .navbar__menu li {
      position: relative;
      padding: 5px 5px;
      cursor: pointer;
  }
  
  .navbar__link {
      display: flex;
      align-items: center;
        color: #6d6d6d;
      text-decoration: none;
      font-size: 14px;
      margin-top: 8px;
      font-weight: 500;
  }

  .navnumber{
    font-size: 14px;
  }
  }

  @media (max-width: 1230px) {
    .navnumber{
      font-size: 13px;
    }

    .navbar__link {
      display: flex;
      align-items: center;
        color: #6d6d6d;
      text-decoration: none;
      font-size: 13px;
      margin-top: 8px;
      font-weight: 500;
  }

  .logo {
    height: 55px;
    width: 130px;
    object-fit: contain;
}

  }
  
  
  
  
  /* -----------------------------------mobile version------------------------------ */

  
  .mobile-navbar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem;
      background-color: rgb(255, 255, 255);
      z-index: 999;

      overflow: hidden;
      border-bottom: 1px solid rgb(54, 54, 54);
    }

    .mobile-header img{
      height: 50px;
    }

    .mobile-header p {
      margin: 0px;
      color: #e15252;
      font-weight: 500;
    }
  
    .mobile-header{
      width: 100%;
    }
    
    .menu-icon {
      font-size: 1.8rem;
      color: #000000;
      background: none;
      border: none;
      cursor: pointer;
      z-index: 999;
    }
    
    .sidebar {
      position: fixed;
      top: 0;
      right: 0;
      width: 70%;
      height: 100%;
      background-color: rgb(241, 240, 240);
      padding: 1rem;
      z-index: 1000;
      overflow-y: auto;
      transition: transform 0.3s ease-in-out;
    }
    
    .sidebar__menu {
      list-style: none;
      padding: 0;
    }
    
    .sidebar__link {
      font-weight: bold;
      color: #000000;
      padding: 1rem 0;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    
    
    .dropdown__itemm {
      padding: 0.5rem 0;
      color: #000000;
      border-bottom: white;
      border-style: solid;
      border-width: 1px;
      border-top: transparent;
      border-left: transparent;
      border-right: transparent;
    }
  
    .sidebar__dropdown {
      list-style: none;
      padding-left: 1rem;
      color: #000000;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease;
      display: block;
    }
    
    .sidebar__dropdown .dropdown__itemm {
      color: #0d0d0d;
      padding: 0.5rem 0;
      display: block;
    }
    
    .sidebar__dropdown .dropdown__itemm:hover {
      color: #e15252;
    }
    
    .sidebar__menu li:hover .sidebar__dropdown,
    .sidebar__menu li:hover .sidebar__dropdown.show {
      max-height: 500px;
    }
    

  

    .sub-dropdown {
      position: absolute;
      top: 0;
      left: 100%;
      background-color: white;
      color: #333;
      border-radius: 5px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.082);
      z-index: 999;
      opacity: 0;
      visibility: hidden;
      transform: translateX(-10px);
      transition: opacity 0.3s ease, transform 0.3s ease;
      padding-left: 2px;
    }


    .dropdown__item:hover .sub-dropdown {
      opacity: 1;
      visibility: visible;
      transform: translateX(0);
    }
    
    .sub-dropdown__item {
      padding: 0.5rem;
      color: #333;
      font-weight: 400;
    }

    .sub-dropdown__item:not(:last-child)::after {
      content: "";
      display: block;
      height: 1px;
      width: 90%;
      margin-top: 4px;
      background-color: rgba(128, 128, 128, 0.269);
    }
    
    .sub-dropdown__item:hover {
      color: #e15252;
    }
    







    /* moblie ------------------------------------ */

    .mobilenav__sidebar {
      position: fixed;
      top: 0;
      right: 0;
      width: 75%;
      height: 100%;
      background-color: #f4f4f4;
      z-index: 999;
      overflow-y: auto;
      transition: transform 0.3s ease-in-out;
      padding: 1rem;
    }
    
    .mobilenav__close {
      color: white;
      font-weight: 600;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 1rem;
    }
    
    .mobilenav__close-icon {
      font-size: 26px;
      color: #e15252;
      cursor: pointer;
    }
    
    .mobilenav__menu {
      list-style: none;
      padding: 0;
      margin: 0;
    }
    
    .mobilenav__menu-item {
      margin-bottom: 1rem;
    }
    
    .mobilenav__link {
      font-weight: bold;
      color: #333;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      padding: 0.5rem 0;
    }
    
    .mobilenav__arrow {
      font-size: 16px;
    }
    
    .mobilenav__dropdown {
      list-style: none;
      padding-left: 1rem;
      transition: max-height 0.3s ease-in-out;
    }
    
    .mobilenav__dropdown-item {
      margin-bottom: 0.5rem;
    }
    
    .mobilenav__sub-dropdown {
      list-style: none;
      padding-left: 1.5rem;
      transition: max-height 0.3s ease-in-out;
    }
    
    .mobilenav__sub-item {
      margin-bottom: 0.5rem;
    }
    
    .mobilenav__sub-link {
      text-decoration: none;
      color: #555;
      display: block;
      padding: 0.3rem 0;
    }
    
    .mobilenav__sub-link:hover {
      color: #e15252;
    }
    

/* Hide the block on larger screens */
.mobile-buttons {
  display: none; /* Hidden by default */
}

.wa-chat-bubble{
  display: none;
}

/* .headbannershark{
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e15252;
  color: white;
  } */

  .headbannershark {
    height: 30px;
    display: flex;
    align-items: center;
    overflow: hidden;
    background-color: #e15252;
    color: white;
    position: relative;
    white-space: nowrap;
    width: 100%;
  }
  
  /* Wraps text for infinite loop effect */
  .scrolling-text {
    display: flex;
    white-space: nowrap;
    width: max-content;
    animation: scrollText 30s linear infinite;
  }

  .mbpropbtn{
    font-size: 12px;
  }

  .navsmalloffer{
    font-size: 12px;
  }
  
  /* Infinite scrolling animation */
  @keyframes scrollText {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-50%);
    }
  }
  
  


/* Show only on mobile screens */
@media (max-width: 768px) {
  .mobile-buttons {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for a raised effect */
    z-index: 1000; /* Make sure it stays above other elements */
  }

  .button-wrapper {
    display: flex;
    justify-content: space-between; /* Distribute buttons evenly */
  }

  .mobile-button-link {
    flex: 1; /* Ensure links share equal space */
    text-decoration: none; /* Remove underline from links */
  }

  .mobile-button {
    width: 100%; /* Full width inside the flex container */
    padding: 15px 0; /* Adjust padding as needed */
    text-align: center; /* Center text */
    font-size: 12px; /* Adjust font size */
    font-weight: 450;
    background-color: #141414; /* Background color */
    color: #ffffff; /* Button text color */
    cursor: pointer;
    border: none; /* Remove border */
    border-radius: 0; /* No rounded corners */
    margin: 0; /* Remove extra margins */
    margin-bottom: -3px ;
  }
}

@media screen and (max-width: 768px) {
  .wa-widget-send-button {
    display: none !important;
  }
  .wa-chat-bubble {
    display: none !important;
  }
}



