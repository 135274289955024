.startupevent-bg{
  background-color: rgb(23, 23, 23);
  color: white;
}


.startupevent-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    height: 100vh;
  }
  
  .startupevent-video-wrapper {
    position: relative;
    width: 77%;
  }
  
  .startupevent-video-player {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  

  .unmute-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
  }
  
  .unmute-button:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .startupevent-social-links {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    width: 100%;
    max-width: 400px;
  }
  

  
  .startupevent-social-links a:hover {
    background-color: #f0f0f0;
  }


    @media (max-width: 768px) {
    .startupevent-video-player {
      object-fit: contain;
    }
    .startupevent-video-wrapper {
        position: relative;
        width: 100%;
      }
  }


  .startupevent-social-links a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px; /* space between icon and label */
    text-decoration: none;
    color: inherit;
  }
  
  .social-label {
    font-size: 14px;
    font-weight: 500;
  }
  