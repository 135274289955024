

/* @font-face {
    font-family: 'Poppins';
    src: url('../public/fonts/Poppins-Light.woff2') format('woff2'),
         url('../public/fonts/Poppins-Light.woff') format('woff');
    font-weight: 300; 
    font-style: normal;
    font-display: swap;
  }
  

  @font-face {
    font-family: 'Poppins';
    src: url('../public/fonts/Poppins-Regular.woff2') format('woff2'),
         url('../public/fonts/Poppins-Regular.woff') format('woff');
    font-weight: 400; 
    font-style: normal;
    font-display: swap;
  }
  

  @font-face {
    font-family: 'Poppins';
    src: url('../public/fonts/Poppins-Medium.woff2') format('woff2'),
         url('../public/fonts/Poppins-Medium.woff') format('woff');
    font-weight: 500; 
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Poppins';
    src: url('../public/fonts/Poppins-Bold.woff2') format('woff2'),
         url('../public/fonts/Poppins-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  
  *{
    font-family: "Poppins", serif;
    font-family: "Roboto";
} */

/* Load PP Mori Extra Light */
@font-face {
    font-family: 'PPMori';
    src: url('../public/fonts/PP-Mori/PPMori-Extralight.otf') format('opentype');
    font-weight: 200; /* Extra Light */
    font-style: normal;
    font-display: swap;
}

/* Load PP Mori Extra Light Italic */
@font-face {
    font-family: 'PPMori';
    src: url('../public/fonts/PP-Mori/PPMori-ExtralightItalic.otf') format('opentype');
    font-weight: 200; /* Extra Light Italic */
    font-style: italic;
    font-display: swap;
}

/* Load PP Mori Regular */
@font-face {
    font-family: 'PPMori';
    src: url('../public/fonts/PP-Mori/PPMori-Regular.otf') format('opentype');
    font-weight: 400; /* Regular */
    font-style: normal;
    font-display: swap;
}

/* Load PP Mori Regular Italic */
@font-face {
    font-family: 'PPMori';
    src: url('../public/fonts/PP-Mori/PPMori-RegularItalic.otf') format('opentype');
    font-weight: 400; /* Regular Italic */
    font-style: italic;
    font-display: swap;
}

/* Load PP Mori SemiBold */
@font-face {
    font-family: 'PPMori';
    src: url('../public/fonts/PP-Mori/PPMori-SemiBold.otf') format('opentype');
    font-weight: 600; /* SemiBold */
    font-style: normal;
    font-display: swap;
}

/* Load PP Mori SemiBold Italic */
@font-face {
    font-family: 'PPMori';
    src: url('../public/fonts/PP-Mori/PPMori-SemiBoldItalic.otf') format('opentype');
    font-weight: 600; /* SemiBold Italic */
    font-style: italic;
    font-display: swap;
}

/* Apply PP Mori to all elements */
* {
    font-family: 'PPMori', sans-serif;
}


.maxwidthcontainer {
    margin: 0 auto;  
    max-width: 1250px;
    width: 100vw;
   
}

.appcontaineroverflow{
    overflow: hidden;
}

.stickyimg{
    height: 120px;
    width: 120px;
    border-radius: 80px;
}


html {
    scroll-behavior: auto !important;
  }
  
  





::selection {
    background: #e15252;
    color: white;
}

/* body {
    -webkit-user-select: none; 
    -moz-user-select: none;  
    -ms-user-select: none;    
    user-select: none;        
} */


.wa-chat-bubble {
display: none !important;
}

.read-more-btn{
    font-size: 14px;
    color: #e15252;
    cursor: pointer;
  }
  .read-more-btn:hover{
    text-decoration: underline;
  }



.wearehirecontainer {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding-left: 20px;
    left: -0.3vw;
    bottom: 25px;
    z-index: 600;
}

/* From Uiverse.io by Botwe-Felix5820 */
.shake-button {
    height: 2em;
    width: 8em;
    border: solid;
    border-width: 0.5px;
    border-color: #000;
    border-radius: 10em;
    font-size: 17px;
    background-color: white;
    font-family: inherit;
    font-weight: 300;
    cursor: pointer;
    /* Adds pointer cursor for better UX */
    box-shadow: #e15252 -2px 2px;
}

.shake-button:hover {
    animation: shake3856 0.3s linear infinite both;
}

@keyframes shake3856 {
    0% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }

    20% {
        -webkit-transform: translate(-2px, 2px);
        transform: translate(-2px, 2px);
    }

    40% {
        -webkit-transform: translate(-2px, -2px);
        transform: translate(-2px, -2px);
    }

    60% {
        -webkit-transform: translate(2px, 2px);
        transform: translate(2px, 2px);
    }

    80% {
        -webkit-transform: translate(2px, -2px);
        transform: translate(2px, -2px);
    }

    100% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
}

@media screen and (max-width:576px) {
    .wearehirecontainer {
        display: none !important;
    }
}