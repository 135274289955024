
.circlee{
    background-color: #D36161;
    height: 15px;
    width: 15px;
    border-radius: 30px;
}
.years{
    z-index: 1;
    margin-left: -6px;
    margin-top: 3px;
    font-size: 20px;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
}



.yearsbox{
    border-width: 2px;
    border-style: solid;
    border-color: black;
    border-left-color: transparent;
    border-right-color: transparent;
}

.yearsbox li{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.activebg{
  background-color: #D36161;
    color: white;
    padding-left: 10px;
    padding-right: 10px;
}


.circlee.active {
    background-color: rgb(137,155,115);
  }


.pointerrr{
    cursor: pointer;
}



.fade-enter {
    opacity: 0;
    transform: scale(0.95);
  }
  
  .fade-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
  }
  
  .fade-exit {
    opacity: 1;
  }
  
  .fade-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }
  

  /* .form-select{
    width: 100px;
    background-color: #F8EDE1;
    border-color: black;
  } */




  .custom-dropdown {
    width: 50px;
    position: relative;
    font-family: Arial, sans-serif;
    z-index: 3;
    user-select: none; 
    -webkit-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none; 
  }
  

.dropdown-header {
  background-color: #D36161;
  padding: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width:70%;
  border-radius: 20px;
  color: white;
  font-weight: 500;
  text-align: center;
  font-size: 14px;
}




.dropdown-listlp {
  position: absolute;
  top: 100%;
  left: -85px;
  width: 130px;
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: #F8EDE1;
  border: 1px solid black;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: opacity 0.5s ease, transform 0.5s ease, visibility 0.5s;
  margin-top: 9px;
  border-radius: 15px;
}

.dropdown-listlp.open {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.websitebtn{
  background-color: #D36161;
  border-radius: 20px;
  width: 80px;
  color: white;
  height: 100%;
  font-size: 15px;
}

.webbtn{
  text-decoration: none;
}

.dropdown-listlp li {
  padding: 5px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.126);
  cursor: pointer;
  font-size: 15px;
}


.dropdown-listlp li:hover {
  background-color: #F2C4A9;
}








/* -------------------------Mobile view--------------------- */
.mobile-menu {
  width: 30%;
  height: 100vh;
  position: absolute;
  z-index: 998; /* Lower than trigger */
  background: #D36161;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  transform: translateX(-100%);
  transition: transform 0.3s;
}



.mobile-menu li {
  width: 85%;
  text-align: center;
  margin: 1rem 0;
  padding: 6px;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  list-style: none;
  border-bottom: 1px solid #fff;
  padding-left: 0px;
}

.mobile-menu-trigger-wrapper {
  position: relative; /* Keeps the trigger container isolated */
  z-index: 4; /* Ensures it stays above the menu */
}


.mobile-menu__trigger {
  cursor: pointer;
  content: "";
  position: absolute;
  width: 130px;
  height: 40px;
  top: 30px;
  left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 22px;

}

.mobile-menu__trigger span{
  width: 30px;
  height: 30px;
  background-color: #D36161;
  border-radius: 15px;
  margin-right: -17px;
}


.mobile-menu_open {
  transform: translateX(0%);
  box-shadow: 0 0 1rem #000000b7;
}
.mobile-menu_open+.overlay {
  visibility: visible;
  opacity: 1;
}






.selectedyear{
  font-weight: 600;
  font-size: 20px;
}


@media (300px <= width <= 700px ) {
  .mbdropdown{
    width: 90vw;
    position: absolute;
    margin-top: 22px;
    
  }

  .dropdown-listlp {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: #F8EDE1;
    border: 0.5px solid rgba(0, 0, 0, 0.824);
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    transition: opacity 0.5s ease, transform 0.5s ease, visibility 0.5s;
    margin-top: 3px;
    border-radius: 13px;
  }

  .dropdown-listlp li {
    padding: 5px;
    color: #333;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    border: 1px solid rgba(0, 0, 0, 0.126);
    cursor: pointer;
  }

  .years{
    z-index: 1;
   text-align: center;
    font-size: 18px;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    width: 100%;
    margin-top: 0px;
}
 

  .custom-dropdown {
    width: 120px;
    position: relative;
    font-family: Arial, sans-serif;
    z-index: 3;
    user-select: none; 
    -webkit-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none; 
  }

  .dropdown-header {
    background-color: #D36161;
    padding: 2px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width:100%;
    border-radius: 20px;
    color: white;
    font-weight: 500;
    text-align: center;
    margin-top: 3px;
  }
  
 
}