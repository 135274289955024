.slide4pic1{
height: 230px;
width: 350px;
}

.slide4pic3{
    height: 330px;
    width: 350px;
    
}




.picbox4{
    width: 550px;
    height: 500px;
    padding-left: 30px;
}

.spacebetweenpic{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.bgresponsice{
    background-color: #F8EDE1;
    height: 100%;
}

.slide4bg{
    height: 180px;
}

.slide4listitems ul {
    list-style-type: disc; 
    /* margin-left: -30px; */
  }

.slide4listitems ul li {
color: #000000;
font-size: 18px;
font-weight: 350;
}
  
/* .slide4listitems ul li::marker {
color: #D36161;  
font-size: 25px; 

} */

.slide4listitems ul li {
    position: relative;
    list-style-type: none; /* Hide the default marker */
    margin-bottom: 15PX;
  }
  
  .slide4listitems ul li::before {
    content: '•'; 
    color: #D36161;  
    font-size: 65px; 
    position: absolute;
    top: -41px; 
    left: -22px; 
  }
  


.slide4lastpara{
    font-size: 16px;
}

.lastparabox{
    border-left: #707070;
    border-style: solid;
    border-top: transparent;
    border-right: transparent;
    border-bottom: transparent;
    padding: 10px;
    border-width: 1px;
    margin-left: -22px;
}

.lastborderline{
    width: 150px;
    border: 1px solid #707070;
    margin-left: -40px;
}

.slide5pic5{
    height: 320px;
}


.slide6pic3{
    height: 250px;
}


.slide5listitems ul li {
    color: #000000;
    /* margin-left: -40px; */
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 5px;
    }

.slide5listitems ul li::marker {
    color: #D36161;  
    font-size: 35px;
    }

.whychooseus2{
    height: 250px;
    margin-top: 20px;
}

.whychooseus1{
    height: 250px;
}


.whysmalllist{
    width: 800px;
}

.howwediff1{
    height: 340px;
}

.howwediff2{
    height: 200px;
    margin-top: -100px;
    margin-left: 200px;
}




.slide7para{
    width: 750px;
}



.wordwidth{
    width: 40vw;
}

.slide71{
    height: 480px;
    padding-top: 20px;
}

.slide91{
    height: 200px;
    width: 260px;
}
.slide92{
    height: 280px;
}

.slide111{
    height: 290px;
    width: 230px;
}
.slide112{
    height: 220px;
    width: 310px;
}


.whychooseusmain1{
    height: 220px;
}
.whychooseusmain2{
    height: 220px;
}

.monologuepic{
    height: 255px;
}


.slide4graph{
    height: 400px;
}


.howwediffpic1{
    height: 95%;
    width: 95%;
}

@media (300px <= width <= 700px ) {
    .slide4pic1{
        height: 150px;
        width: 200px;
        }
        
        .slide4pic3{
            height: 240px;
        width: 280px;
        }
        
        .slide4pic4{
            height: 250px;
        }
        .spacebetweenpic{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 80%;
        }

        .slide4listitems{
            width: 90vw;
        }
        
        .slide4listitems ul li {
        margin-left: 25px;
        font-size: 18px;
        font-weight: 400;
        }
          
        .slide8mbpic{
            height: 300px;
            width: 300px;
        }
       
        
        .slide4listitems ul li {
            position: relative;
            list-style-type: none; 
            margin-bottom: 15PX;
          }
          
          .slide4listitems ul li::before {
            content: '•'; 
            color: #D36161;  
            font-size: 55px; 
            position: absolute;
            top: -33px; 
            left: -20px; 
          }
          
          .lastparabox{
            border-left: #707070;
            border-style: solid;
            border-top: transparent;
            border-right: transparent;
            border-bottom: transparent;
            padding: 10px;
            border-width: 0px;
            margin-left: 10px;
        }
        
        .lastborderline{
            width: 150px;
            border: 0px solid #707070;
            margin-left: -40px;
        }

        .slide5listitems ul li {
            color: #000000;
            margin-left: 0px;
            font-size: 18px;
            font-weight: 400;
            margin-bottom: 10px;
            }
        
        .slide5listitems ul li::marker {
            color: #D36161;  
            font-size: 25px; 
            }

           

            /* .mbhowediff{
                margin-top: -100px;
            } */

            .howwediff1{
                height: 180px;
            }
            
            .howwediff2{
                height: 150px;
                margin-top: -100px;
                margin-left: 200px;
                margin-bottom: 20px;
            }

            .whysmalllist{
                width: 100vw;

            }

            .whysmalllist ul li {
                color: #000000;
                margin-left: 0px;
                font-size: 15px;
                font-weight: 400;
                margin-bottom: 5px;
                }

                .wordwidth{
                    width: 90vw;
                }

                .picbox4{
                    width: 100vw;
                    height: 350px;
                    padding-left: 0px;

                }
       
}




@media (min-width: 1243px) and (max-width: 1400px) {
    .slide5listitems ul li {
        font-size: 18px;  
    }
    .slide4listitems ul li {
        font-size: 19px; 
    }
    .monolog p{
        font-size: 19px;
    } 
    .slide91{
        height: 240px;
        width: 340px;
        margin-right: -70px;
    }
    .slide92{
        height: 300px;
        margin-left: -10px;
        
    }
    .whychooseusmain1{
        height: 250px;
    }
    .whychooseusmain2{
        height: 250px;
    }
    .monologuepic{
        height: 280px;
        margin-top: -60px;
    }
    .slide4lastpara{
        font-size: 18px;
    }
    .slide4listitems ul li {
        margin-bottom: 28PX;
      }

      .slide4pic1{
        height: 250px;
        width: 370px;
       
        }
        
   
    
}

@media (min-width: 1400px) and (max-width: 1800px) {
    .slide5listitems ul li {
        font-size: 24px;
        margin-bottom: 20px;
    }
    .slide4listitems ul li {
        font-size: 24px;
    }
    .monolog p{
        font-size: 22px;
    }
    .slide4pic1{
        height: 250px;
        width: 370px;
        }
        
    .slide4pic3{
        height: 330px;
        width: 370px;
    }
    .slide91{
        height: 270px;
        width: 360px;
        margin-right: -70px;
    }
    .slide92{
        height: 350px;
    }
    .slide111{
        height: 320px;
        width: 250px;
    }
    .slide112{
        height: 250px;
        width: 340px;
    }
    .whychooseus2{
        height: 280px;
        width: 200px;
        margin-top: 20px;
    }
    
    .whychooseus1{
        height: 280px;
        width: 200px;
    }
    .whychooseusmain1{
        height: 280px;
    }
    .whychooseusmain2{
        height: 280px;
    }
    .monologuepic{
        height: 300px;
        margin-top: -40px;
    }
    .slide4lastpara{
        font-size: 20px;
    }
    .slide4listitems ul li {
        margin-bottom: 30PX;
      }

      .howwediff1{
        height: 470px;
    }
    
    .howwediff2{
        height: 250px;
        margin-top: -150px;
        margin-left: 500px;
    }
    
}

@media (min-width: 1800px) and (max-width: 3000px) {
    .slide5listitems{
        padding-top: 20px;
    }

    .slide4listitems{
        padding-top: 40px;
    }
   
    .slide5listitems ul li {
        font-size: 28px;
        font-weight: 400;
        margin-bottom: 25px;
    }
    .slide4listitems ul li {
        font-size: 28px;
        font-weight: 400;
        margin-bottom: 10px;
    }
    .monolog p{
        font-size: 25px;
    }
    .slide4pic1{
        height: 280px;
        width: 430px;
        }
        
        .slide4pic3{
            height: 480px;
            width: 430px;
        }

        .slide91{
            height: 320px;
            width: 370px;
            margin-right: -20px;
        }
        .slide92{
            height: 290px;
            margin-top: -50px;
            margin-left: 60px;
        }
        .slide111{
            height: 350px;
            width: 260px;
        }
        .slide112{
            height: 250px;
            width: 290px;
        }
        .whychooseus2{
            height: 310px;
            width: 250px;
            margin-top: 20px;
        }
        
        .whychooseus1{
            height: 330px;
            width: 250px;
        }
        .whychooseusmain1{
            height: 300px;
        }
        .whychooseusmain2{
            height: 300px;
            padding-left: 70px;
        }
        .monologuepic{
            height: 350px;
            margin-top: -80px;
        }
        .slide4lastpara{
            font-size: 22px;
        }
          .slide4listitems ul li::before {
            content: '•'; 
            color: #D36161;  
            font-size: 60px; 
            position: absolute;
            top: -28px; 
            left: -22px; 
  }
  .slide4listitems ul li {
    margin-bottom: 35PX;
  }

  .howwediff1{
    height: 570px;
}

.howwediff2{
    height: 350px;
    margin-top: -200px;
    margin-left: 500px;
}

.logowelcome{
    height: 150px;
}


  
}

