.enquiry-pop-up-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(87, 87, 87, 0.053); /* 70% opacity white */
    backdrop-filter: blur(1px); /* Add a frosted glass effect */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .pop-up-error-text{
    color: #e15252;
    font-weight: 500;
  }
  
  .pop-up-content {
    background: #fff;
    border-radius: 10px;
    width: 600px;
    max-width: 90%;
    padding: 30px;
    position: relative;
    max-height: 90vh; 
  overflow-y: auto; 
  }
  
  .pop-up-close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    color: #e15252;
    border: none !important;
    font-size: 30px;
    width: 40px !important;
    background-color: white;
  }
  
  .pop-up-form-heading {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .pop-up-input-group {
    margin-bottom: 15px;
    position: relative;
  }
  
  .pop-up-input,
  .pop-up-select {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .pop-up-label {
    position: absolute;
    top: -10px;
    left: 10px;
    background: #fff;
    padding: 0 5px;
    font-size: 14px;
    color: #666;
  }
  
  .pop-up-radio-group label,
  .pop-up-checkbox-group label {
    margin-right: 15px;
  }
  
  .pop-up-submit-button {
    background: #e15252 !important;
    color: white !important;
    padding: 12px 20px;
    border: none;
    border-radius: 5px !important;
    cursor: pointer;
    font-size: 16px;
    width: 100% !important;
  }
  
  .pop-up-submit-button:hover {
    background: #e15252 !important;
  }
  
  .pop-up-privacy-notice {
    font-size: 14px;
    color: #777;
    margin: 10px 0;
  }
  

  .pop-up-receiveUpdates{
    margin-top: -3px;
  }