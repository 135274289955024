.welcomecontainer{
    height: 80vh;
    width: 80vw;
}

.welcomebg{
    background-image: url('../assests/welcomebg.avif');
    background-size:contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 200px;
    width: 200px;
    
}

.logowelcome{
    height: 150px;
}

.insideboxx{
    height: 100%;
}


.welcomeheading{
    margin-top: 60px;
}

.zigler{
    color: #D36161;
    font-weight: 600;
}

.higlitess{
    font-style: italic;
    font-weight: 600;
}


.lapp{
    height: 450px;
    margin-top: 60px;
}

.lobosize{
    height: 150px;
    width: 190px;
}

.logoboxxx{
    width:70vh;
}

.logoboxxx h2{
    font-size: 40px;
    font-weight: 450;
}

.lineimage{
    height: 100px;
}


.arrowsintro{
    position: absolute;
    width: 100vw;
    height: 80vh;
    padding: 30px;
}

.intro2pic{
    margin-top: 80px;
}

.ourwordheight{
    height: 80vh;
}

.slide2bg{
    background-image: url('../assests/slide2bg.avif');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.slide1bg{
    background-image: url('../assests/intro1bg.avif');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@media (300px <= width <= 700px ) {
    .welcomeheading{
        margin-top: 40px;
    }

   
    .lineimage{
        height: 100px;
        width: 290px;
        margin-top: 70px;
    }
  
    .logoboxxx{
        height: 80vh;
    }
    .arrowsintro{
      
        width: 100vw;
        height: 100vh;
        padding: 5px;
        

    }
    .welcomecontainer{
        height: 100vh;
        width: 80vw;
    }
    .logowelcome{
        height: 140px;
    }
    .welcomebg{
        background-image: url('../assests/welcomebg.avif');
        background-size:contain;
        background-position: center;
        background-repeat: no-repeat;
        height: 180px;
        width: 200px;
        
    }

    .logoboxxx{
        height: 90vh;
    }
   
    .ourwordheight{
        height: 100vh;
    }
}

.maxwidtht{
    max-width: 1200px;
}