

  
  .locationbanner {
    position: relative;
    text-align: center;
    color: white;
    height: 400px;
    overflow: hidden;
  }


  
  .locationbanner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: -1;
  }
  
  .locationbanner::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.3));
    z-index: 0; 
    pointer-events: none; 
  }

  .card-container.highlighted {
    background-color: #f0f0f058; 
    border: 1px solid #d6d6d6; 
    border-radius: 8px; 
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    -webkit-animation: heartbeat 1.5s ease-in-out both;
	animation: heartbeat 1.5s ease-in-out both;
  }

  @-webkit-keyframes heartbeat {
    from {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    10% {
      -webkit-transform: scale(0.91);
              transform: scale(0.91);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    17% {
      -webkit-transform: scale(0.98);
              transform: scale(0.98);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    33% {
      -webkit-transform: scale(0.87);
              transform: scale(0.87);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    45% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
  }
  @keyframes heartbeat {
    from {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    10% {
      -webkit-transform: scale(0.91);
              transform: scale(0.91);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    17% {
      -webkit-transform: scale(0.98);
              transform: scale(0.98);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    33% {
      -webkit-transform: scale(0.87);
              transform: scale(0.87);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    45% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
  }
  
  
  
  .locationbanner h1 {
    margin-top: 20px;
    font-size: 50px;
    font-weight: 400;
    z-index: 3;
  }

  .locationbanner h2 {
    margin-top: 20px;
    font-size: 20px;
  
    z-index: 3;
    width: 70%;
  }

  
  .locationcontent {
    max-width: 90%;
    margin: 0 auto;
    font-size: 15px;
    z-index: 3;
     
  }

  .locationcontent p{
    margin-bottom: 0px;
  }
  
 .selectboxcontainer{
    width: 1050px;
    /* margin-top: 20px; */
    flex-direction: row;
 }




.selectboxdropdwon {
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 50px; 
  }
  

  .selectboxdropdwon select {
    width: 100%;
    padding: 10px 15px;
    border: none;
    border-radius: 35px;
    background-color: #B9AD8E;
    color: #ffffff;
    font-size: 17px;
    font-family: "Arial", sans-serif;
    cursor: pointer;
    transition: all 0.3s ease; 
    appearance: none; 
    background-image: linear-gradient(45deg, transparent 50%, #ffffff 50%),
      linear-gradient(135deg, #ffffff 50%, transparent 50%);
    background-position: calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px);
    background-size: 5px 5px, 5px 5px;
    background-repeat: no-repeat;
  }
  

  

  .selectboxdropdwon select:focus {
    outline: none; 
    color: white;
    
  }
  

  .selectboxdropdwon select option {
    transition: all 0.3s ease;
  }
  

  
  .alllocationbtn{
    background-color: #e15252;
    border-radius: 30px;
    border: none;
    padding: 10px;
    width: 180px;
    color: white;
    font-weight: bold;
height: 50px;
  }



  /* Main container */
.card-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 20px;
    background-color: #fff;
    max-width: 1100px;
    margin: 20px auto;
    align-items: center;
     
  }
  

  /* .card-image-container {
    width: 480px;
    height: 260px;
  }
  
  .card-image {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
    */

    .card-image-container {
      position: relative;
      width: 480px;
      height: 260px;
      overflow: hidden;
    }
    
    .card-image {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      transition: transform 0.5s ease-in-out;
    }
    
    .arrow-btn {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background-color: transparent;
      color: rgb(255, 255, 255);
      border: none;
      font-size: 20px;
      cursor: pointer;
      border-radius: 50%;
      z-index: 10;
    }
    
    .left-arrow {
      left: 10px;
    }
    
    .right-arrow {
      right: 10px;
    }
    

    
  
  /* Details container */
  .card-details-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  /* Header with title and button */
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .card-title {
    font-size: 30px;
    margin: 0;
  }
  
  .phase-button {
    background-color: #DEC596;
    color: #ffffff;
    border: none;
    padding: 4px 10px;
    border-radius: 5px;
    font-size: 0.9rem;
    cursor: default;
    padding-top: 6px;
  }
  
  /* Location and distance */
  .card-location,
  .card-distance {
    margin: 0;
    font-size: 1rem;
    color: #666;
  }
  
  /* Price and enquiry button */
  .card-pricee {
    color: #e15252;
    font-size: 1.5rem;
     
    font-weight: 600;
  }
  
  .enquiry-button {
    background-color: #e15252;
    color: white;
    border: none;
    padding: 7px 20px;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 150px;
  }
  
  .enquiry-button:hover {
    background-color: #b84848;
  }
  
  /* Features container */
  .features-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .feature-button {
    background-color: #f5f5f5;
    color: #666;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 0.8rem;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .feature-button:hover {
    background-color: #f8e1b8;
    color: #333;
  }
  
  .view-details-button {
    background-color: transparent;
    color: #e15252;
    border: none;
    font-size: 0.9rem;
    font-weight: bold;
    cursor: pointer;
  }
  
  .view-details-button:hover {
    text-decoration: underline;
  }

  .locationflexdirection{
    display: flex;
    flex-direction: column;
  }

  .selectboxdropdwon.disabled select {
   
    cursor:no-drop;
    
  }

  .locationbottomcontent{
    height: auto;
    background-color: #e15252;
    color: white;
    text-align: center;
    padding: 30px;
   
  }

  .hrlinee{
    background-color: black;
    width: 65% !important;
    padding: 1px;
  }


  /* Container styling */
.propertymb-dropdown-container {
  position: relative;
  width: 350px; /* Adjust width as needed */
  margin: 1rem 0;
  font-family: 'Arial', sans-serif;
}

/* Dropdown header */
.propertymb-dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem;
  border-radius: 5px;
  background-color: #e15252 !important;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: white !important;
}



/* Arrow styling */
.propertymb-dropdown-arrow {
  font-size: 0.8rem;
  transition: transform 0.3s ease;
}

.propertymb-dropdown-arrow.rotate {
  transform: rotate(180deg);
}

/* Dropdown menu */
.propertymb-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: #fcfcfc;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  animation: fadeIn 0.3s ease;
  z-index: 10;
}

/* Dropdown items */
.propertymb-dropdown-item {
  padding: 0.8rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.propertymb-dropdown-item:hover {
  background-color: #f1f1f1;
}


.wearehiringbox {
  position: relative;
  display: inline-block; 
  background-color: #2d2d2d;
  color: white;
  font-weight: 500;
  margin-left: 5px;
  padding: 2px;
  overflow: hidden;
  border-radius: 4px;
  border: none;
  height: 40px;
  width: 170px;
}

.wearehiringbox::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%; 
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
      120deg,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0) 70%
  );
  opacity: 0.6;
  animation: shine 1.5s ease-out infinite; 
  transform: translateX(0);
}

@keyframes shine {
  0% {
      left: -100%;
  }
  100% {
      left: 100%;
  }
}

/* Fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.city-buttons-container{
  justify-content: space-around;
  
}



.selectboxcontainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  /* margin-top: 20px; */
}

.city-buttons-container,
.location-buttons-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

}

.city-button,
.location-button,
.alllocationbtn {
  padding: 10px 20px;
  border: 2px solid #e15252;
  border-radius: 5px;
  background-color: transparent;
  color: #000000;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  height: 50px;
}

.city-button.active,
.location-button.active,
.alllocationbtn.active {
  background-color: #e15252;
  color: white;
}

.ourlocationstxt{

  font-size: 35px;
margin-top: 60px;
}

.outlocationsubtext{
  font-size: 15px;
  text-align: center;
  width: 70%;
  margin-top: 20px;
  text-align: justify; /* Distribute text evenly across the width */
  line-height: 1.6; /* Adjust line height for better readability */
  word-break: break-word; /* Prevent words from overflowing */
  hyphens: auto; /* Enable word hyphenation for better justification */
}

.totop {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.totop button {

  font-size: 36px;
  background-color: #434343;
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.totop button:hover {
  background-color: #000000;
}

  
  @media (max-width: 768px) {
    .totop button {

      font-size: 26px;
    }
    .locationbanner {
      position: relative;
      text-align: center;
      color: white;
      height: 200px;
      overflow: hidden;
    }

    .locationbanner h2 {
      margin-top: 10px;
      font-size: 16px;
       
      z-index: 3;
      width: 95%;
    }
  
    .outlocationsubtext{
     width: 95%;
      
    }

    .ourlocationstxt{
      font-size: 20px;
      margin-top: 15px;
      text-decoration: underline;
    }

    .selectboxcontainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      /* margin-top: 20px; */
    }

    .city-buttons-container{
      justify-content: start;
      width: 100vw;
      padding: 10px;
      margin: auto;
      max-width: 90%;
    }

    .city-button,
    .location-button,
    .alllocationbtn {
      padding: 1px 13px;
      border: 1px solid #e15252;
      border-radius: 5px;
      background-color: transparent;
      color: #000000;
      font-size: 16px;
      cursor: pointer;
      transition: all 0.3s ease;
      height: 35px;
    }
    .card-container {
      flex-direction: column;
      align-items: center;
      gap: 10px;
     
    }

      /* Image container */
  .card-image-container {
    width: 100%;
    height: 200px;
  }
  
   

    .coworkingcontainer {
        margin-top: 0px;
       
      }

      .phase-button {
       width: 180px;
      }

      .locationflexdirection{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

        
  .locationbanner h1 {
    margin-top: 20px;
    font-size: 20px;
  }
  
  .locationcontent {
    font-size: 12px;
  }

  .selectboxcontainer{
    width: 100vw;
 }




.selectboxdropdwon {
    width: 300px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px; 
  }
  

  .selectboxdropdwon select {
    width: 100%;
    padding: 6px 15px;
    border: none;
    border-radius: 35px;
    background-color: #B9AD8E;
    color: #ffffff;
    font-size: 13px;
    font-family: "Arial", sans-serif;
    cursor: pointer;
    transition: all 0.3s ease; 
    appearance: none; 
    background-image: linear-gradient(45deg, transparent 50%, #ffffff 50%),
      linear-gradient(135deg, #ffffff 50%, transparent 50%);
    background-position: calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px);
    background-size: 5px 5px, 5px 5px;
    background-repeat: no-repeat;
  }
  

  

  .selectboxdropdwon select:focus {
    outline: none; 
    color: white;
    
  }
  

  .selectboxdropdwon select option {
    transition: all 0.3s ease;
  }
  

  
  .alllocationbtn{
    padding: 5px;
    width: 130px;
    margin-bottom: 10px;
    height: 35px;
  }

  .selectboxcontainer{
   
    /* margin-top: 20px; */
    flex-direction: column;
    align-items: center;
 }

 .mblocationcard{
    border: 1px solid rgba(191, 191, 191, 0.518);
    width: 320px;
    border-radius: 10px;
    box-shadow: 2px 4px 5px 0px rgba(0, 0, 0, 0.1);
 }


 .card-title {
    font-size: 23px;
    font-weight: bold;
    margin: 0;
     
  }
  

  
  /* Location and distance */
  .card-location,
  .card-distance {
    margin: 0;
    font-size: 15px;
    color: #666;
  }
  
  /* Price and enquiry button */
  .card-pricee {
    color: #e15252;
    font-size: 1.3rem;
     
    margin-bottom: 0px;
  }
  
  .enquiry-button {
    background-color: #e15252;
    color: white;
    border: none;
    padding: 4px;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 110px;
  }
  
  .enquiry-button:hover {
    background-color: #b84848;
  }
  
  /* Features container */
  .features-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .feature-button {
    background-color: #f5f5f5;
    color: #666;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 0.6rem;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .feature-button:hover {
    background-color: #f8e1b8;
    color: #333;
  }
  
  .view-details-button {
    background-color: transparent;
    color: #e15252;
    border: none;
    font-size: 0.9rem;
    font-weight: bold;
    cursor: pointer;
  }

  .locationbottomcontent{
    height: auto;
   

  }

  }
  



  

