.offersbox{
    text-align: center;
    gap: 5px;
    width: 300px !important;
}


.off-location{
    font-size: 15px;
    margin: 0px;
}

.off-fixed-rentfre{
    margin-top: 40px;
    font-size: 24px;
    font-weight: 500;
}

.offersbox p{
    font-size: 12px;
    margin: 0px;
}

.vo-off{
    font-size: 23px;
    font-weight: 500;
}

.validandexplore{
    bottom: 30px;
}

.dp-total{
    color: #e15252;
    font-size: 18px;
    margin-bottom: 5px;
}

.dp-daypassprice{
    font-size: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
}


.dp-credits{
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.dp-additionalcred{
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 400;
    color: gray;
}

.off-title{
    font-size: 23px !important;
    margin-bottom: 0px !important;
}

.dp-limitedoff{
    font-size: 12px;
    color: gray;
}

.offerimngs{
    height: 250px !important;
    width: 250px;
    margin: auto;
    border-radius: 150px !important;
}


@media (max-width: 768px) {
    .offerimngs{
        height: 200px !important;
        margin: auto;
        border-radius: 150px !important;
    }
}











