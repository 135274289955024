.inflow-secondsection {
    text-align: center;
    margin-top: 30px;
    padding: 20px;
  }
  
  .inflow-secondsection h2 {
    font-size: 35px;
  }
  
  .inflow-info-flow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    position: relative;
    margin-bottom: 30px;
  }
  
  .inflow-faq-section {
    background: rgb(34, 34, 34);
    padding: 20px;
    border-radius: 20px;
    color: white;
    text-align: center;
    height: 250px;
    width: 250px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .inflow-arrow {
    font-size: 40px;
    color: rgb(0, 0, 0);
    font-weight: bold;
  }
  
  .inflow-down-arrow {
    position: absolute;
    right:  42%;
    transform: translateX(-50%);
    bottom: -50px;
    font-size: 40px;
    color: rgb(0, 0, 0);
    font-weight: bold;
  }
  
  .inflow-icons {
    font-size: 40px;
    margin-bottom: 15px;
    color: white;
  }
  
  .inflow-info-text {
    font-size: 18px;
    line-height: 1.6;
    color: #ffffff;
    text-align: center;
  }

  .mbmarginginfo{
    margin-bottom: 50px;
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .inflow-info-flow {
      flex-wrap: wrap;
      justify-content: center;
    }
    .mbmarginginfo{
        margin-bottom: 0px;
      }
  }
  
  @media (max-width: 768px) {
    .inflow-arrow,
    .inflow-down-arrow {
      font-size: 30px;
    }
  
    .inflow-faq-section {
      width: 200px;
      height: 200px;
    }
  }
  
  @media (max-width: 480px) {
    .inflow-arrow,
    .inflow-down-arrow {
      font-size: 25px;
    }
  
    .inflow-faq-section {
      width: 180px;
      height: 180px;
    }
  
    .inflow-info-text {
      font-size: 13px;
    }
  }
  