/* Modal Overlay */
.altfkey-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Modal Container */
  .altfkey-modal-content {
    background: #fdfdfd;
    border-radius: 16px;
    padding: 25px;
    width: 90%;
    max-width: 850px;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  }
  
  /* Close Button */
  .altfkey-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    color: #e15252;
    transition: color 0.3s ease;
  }
  

  
  /* Header Section */
  .altfkey-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #b9b9b9;
    padding-bottom: 15px;
    margin-top: 20px;
  }
  
  .altfkey-header h1 {
    font-size: 28px;
    font-weight: 400;
    color: #222;
    margin-bottom: 0px;
  }
  
  .altfkey-radius-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .altfkey-radius-container label {
    font-size: 1rem;
    color: #555;
  }
  
  .altfkey-radius-input {
    padding: 2px 10px;
    border: 1px solid #909090;
    width: 100px;
    border-radius: 0px;
    margin-top: 0px;
  }
  
  /* Error Message */
  .altfkey-error {
    color: #ff4d4f;
    text-align: center;
    font-size: 0.9rem;
    margin-bottom: 15px;
  }
  
  /* No Results */
  .altfkey-no-results {
    text-align: center;
    color: #666;
    font-size: 1rem;
  }
  
  /* Locations Section */
  .altfkey-locations-section {
    margin-top: 20px;
  }
  
  /* Featured (Top) Location */
  .altfkey-featured-location {
    display: flex;
    overflow: hidden;
    margin-bottom: 30px;
  }
  
  .altfkey-featured-img {
    width: 50%;
    object-fit: cover;
    border-radius: 20px;
  }
  
  .altfkey-featured-info {
    padding: 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .altfkey-featured-info h2 {
    font-size: 1.5rem;
    color: #222;
    margin-bottom: 10px;
  }
  
  .altfkey-featured-info p {
    font-size: 1rem;
    color: #181818;
    margin-bottom: 0px;
  }



  .altfkey-featured-btn {
    padding: 2px 3px;
    background: transparent;
    color: rgb(34, 34, 34);
    border: 1px solid gray;
    border-radius: 0px;
    cursor: pointer;
    transition: background 0.3s ease;
    margin-top: 10px;
    width: 140px;
  }
  
  .altfkey-featured-btn:hover {
    background: #e15252;
    color: white;
    border: none;
  }
  
  /* Other Locations */
  .altfkey-other-locations h3 {
    border-bottom: 1px solid #b9b9b9;
    border-top: 1px solid #b9b9b9;
    font-size: 1.4rem;
    margin-bottom: 15px;
    color: #333;
    padding: 9px 0px;
  }
  
  .altfkey-location-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 94%;
  }
  
  .altfkey-location-card {
    background: #fff;
    border-radius: 20px;
    box-shadow: 4px 3px 8px rgba(0, 0, 0, 0.297);

    overflow: hidden;
    transition: transform 0.2s ease-in-out;
    width: 230px;
  }
  
  .altfkey-location-card:hover {
    transform: translateY(-5px);
  }
  
  .altfkey-location-img {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  
  .altfkey-location-details {
    padding: 15px;
    height: 200px;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }
  
  .altfkey-location-details h4 {
    font-size: 18px;
    color: #333;
    margin-bottom: 8px;
    height: 40px;
  }
  
  .altfkey-location-details p {
    font-size: 15px;
    color: #2a2a2a;
    margin-bottom: 0px;
  }

  .altfkey-location-details span {
   margin-bottom: 10px;
  }
  
  .altfkey-directions-btn {
    padding: 2px 3px;
    background: transparent;
    color: rgb(34, 34, 34);
    border: 1px solid rgb(155, 155, 155);
    border-radius: 0px;
    cursor: pointer;
    transition: background 0.3s ease;
    width: 140px;
    margin: 0px auto;
    font-size: 14px;
  }


  .exploremore{
    font-size: 13px;
    color: gray;
    margin-top: 5px;
    text-decoration: underline;
  }
  
  .altfkey-directions-btn:hover {
    background: #e15252;
    color: white;
    border: none;
  }

  
  /* Responsive Design */
  @media (max-width: 768px) {
    .altfkey-featured-location {
      flex-direction: column;
    }
    .altfkey-header {
      flex-direction: column;
      align-items: start;
      gap: 10px;
    }
  
    .altfkey-featured-img {
      width: 100%;
      height: 200px;
    }
  
    .altfkey-featured-info {
      padding: 15px;
    }
      /* Modal Container */
  .altfkey-modal-content {
    padding: 15px;
  }

  .altfkey-location-grid {
    justify-content: center;
  }

  .altfkey-header h1 {
    font-size: 20px;
  }



  }
  