.workspacesolutions {
  margin-top: 120px;
  max-width: 1300px;
  width: 100%;
}


.po-cards-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 30px;
    margin-bottom: 40px;
    width: 80%;
  }
  
  .po-card {
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 20px;
    box-shadow: 6px 5px 8px rgba(0, 0, 0, 0.297);
    overflow: hidden;
    padding: 20px;
    width: 280px;
    margin-top: -100px;
    height: 630px;
    display: flex;
    padding-top: 100px;
    flex-direction: column;
    justify-content: space-between; 
  }
  
  .po-card-image img {
    width: 250px;
    height: auto;
    border-radius: 8px 8px 0 0;
    object-fit: cover;
  }
  
  .po-card-title {
    font-size: 1.25rem;
    margin: 15px 0 15px;
    font-weight: 500;
    color: #333;
    text-align: center;
  }
  
  .po-card-features {
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: 0.9rem;
    line-height: 1.5;
    color: #555;
    flex-grow: 1;
  }

  .po-icon{
    height: 15px;
  }
  
  .po-card-features li {
    margin-bottom: 10px;
  }
  
  .po-card-button {
    display: inline-block;
    margin-top: 20px;
    padding: 0px 10px;
    font-size: 1rem;
    color: #363636;
    border: 2px solid #7e7e7e;
    border-radius: 4px;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 150px;
    height: 35px;
  }
  
  .po-card-button:hover {
    background: #585858;
    color: #fff;
  }

  .po-webcontent ul li{
      font-size: 13px;
  }

  .read-more-btn{
    font-size: 14px;
    color: #e15252;
    cursor: pointer;
  }
  .read-more-btn:hover{
    text-decoration: underline;
  }

  .po-headcontent {
    text-align: justify; /* Distribute text evenly across the width */
    line-height: 1.6; /* Adjust line height for better readability */
    word-break: break-word; /* Prevent words from overflowing */
    hyphens: auto; /* Enable word hyphenation for better justification */
  }
  

  .po-idealfor{
    list-style: disc;
  }

  .po-startsat{
    font-weight: 500;
    color: #363636;
    font-size: 15px;
   text-align: center;
  }

  .idealli li {
    margin-bottom: 0px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .po-cards-container {
      flex-direction: column;
      gap: 20px;
      width: 300px;

    }
    .workspacesolutions {
      margin-top: 0px;
    }
  
    .po-card {
      width: 100%;
    }
    .po-headcontent{
      width: 100%;
      font-size: 12px;
    }
    .po-card-image img {
      width: 200px;
    }
  }
  




  

  
