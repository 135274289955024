.office-space-2-container{
  width: 80%;
  margin-top: 20px;
  margin-bottom: 50px;
}

.office-space-2-para{
  line-height: 25px;
  color: black;
  font-weight: 300;
}


.office-space-Ourservices-para{
  font-size: 14px !important;
}

.office-space-nearby{
  text-decoration: underline;
  margin-top: 10px;
}

.office-space-find-container{
  width: 95% !important;
  align-items: center;
}


.office-space-po-card{
  width: 350px !important;
}

.office-space-nearby-btn{
  background-color: #e15252;
  border: none;
  color: white;
  font-size: 12px;
}

.office-space-amenities{
  width: 80%;
}


.office-space-animities-h3{
  font-size: 40px;
  margin-bottom: 30px;
  margin-top: 30px;
}




.office-space-amenities-container {
  display: flex;              /* Lay out items in a row */
  flex-wrap: wrap;            /* Allow wrapping on small screens */
  justify-content: center;    /* Center items horizontally */
  gap: 8rem;                  /* Spacing between items */
  margin: 2rem 0;             /* Top/bottom margin for breathing room */
}

.office-space-amenities-item {
  display: flex;
  flex-direction: column;     /* Stack icon above text */
  align-items: center;        /* Center icon and text */
  text-align: center;
  min-width: 80px;            /* Prevent items from shrinking too much */
}

.office-space-amenities-icon {
  width: 80px;
  height: auto;
}

.office-space-amenities-text {
  margin-top: 0.5rem;
  font-size: 16px;
  color: #000;                /* Adjust text color as needed */
}

.office-space-banner-mt{
  margin-top: 50px;
}

.office-space-heading{
  font-size: 16px !important;
 margin-top: 10px !important;
 margin-bottom: 10px !important;
}

.office-space-pointers{
  text-align: left;
  font-size: 14px;
}

.office-space-pricing{
  font-size: 15px !important;
  margin-top: 10px !important;
  font-weight: 600 !important;

}


.office-space-whychoose {
  padding: 20px;
  margin: 0 auto;
  max-width: 1200px;
  text-align: center;
}

.office-space-whychoose__header {
  margin-bottom: 20px;
}

.office-space-whychoose__title {
  font-size: 2rem;
  margin: 0;
  line-height: 1.2;
}

.office-space-whychoose__body {
  font-size: 1rem;
  line-height: 1.5;
  color: #333;
}

.office-space-whychoose__list {
  list-style: none;
  padding: 0;
  margin: 0 0 20px 0;

  margin-bottom: 20px;
}

.office-space-whychoose__list-item {
  margin-bottom: 10px;
  padding-left: 1.2em;
  position: relative;
  line-height: 25px;
}

.office-space-whychoose__list-item::before {
  content: "•";
  position: absolute;
  left: 0;
  color: #ffffff; 
}

.office-space-whychoose__tax-note,
.office-space-whychoose__contact {
  margin: 10px 0;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .office-space-whychoose {
    padding: 15px;
  }
  
  .office-space-whychoose__title {
    font-size: 1.5rem;
  }
  
  .office-space-whychoose__body {
    font-size: 0.9rem;
  }
  .office-space-mb-box{
    gap: 7px;
  }
  .office-space-whychoose__list {
    width: 100%;
  }
}



@media (max-width: 768px) {
  .office-space-amenities-container {
      display: flex;              /* Lay out items in a row */
      flex-wrap: wrap;            /* Allow wrapping on small screens */
      justify-content: center;    /* Center items horizontally */
      gap: 2rem;                  /* Spacing between items */
      margin: 2rem 0;             /* Top/bottom margin for breathing room */
    }
    .office-space-amenities-icon {
      width: 30px;
      height: auto;
    }
    .office-space-amenities-text {
      margin-top: 0.5rem;
      font-size: 13px;
      color: #000;                /* Adjust text color as needed */
    }
    .office-space-2-container{
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
  }
  .office-space-banner-mt{
      margin-top: 0px;
    }
    .office-space-2-para{
      line-height: 20px;
  }
}
