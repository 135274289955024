.carosel-container{
  height: 600px;
}


.sharkbannerimg{
height: 100%;
width: 100%;
border-radius: 15px;
  object-fit: cover;

}

#new-cards-section {
min-height: 1700px; /* Adjust based on the expected height */
}

#our-client-section {
min-height: 200px; /* Adjust based on the expected height */
}

#virtual-tour-section {
min-height: 400px; /* Adjust based on the expected height */
}

#faq-section {
min-height: 400px; /* Adjust based on the expected height */
}



.carosele-image{
  height: 440px;
  border-radius: 20px;
  width: 630px;
  object-fit: cover;
}

.dotspic {
  position: absolute;
  z-index: -3;
  margin-top: -50px;
  height: 150px;
  margin-left: -90px;
  opacity: 0.3;
}




.carosel-container h2{
  font-size: 25px;
  font-weight: 300;
  color: gray;
  line-height: 40px;
}

.subheadinghome1{
font-size: 25px;
font-weight: 600 !important;
color: gray;
line-height: 40px;
color: #e15252 !important;
}

.explorebtn{
  padding: 13px;
  background-color: white;
  border-radius: 7px;
  border: 1px solid rgba(0, 0, 0, 0.477);
}

.enquirebtn{
  background-color: #e15252;
  color: white;
  margin-left: 10px;
  border: none;
  padding: 10px 20px;
  border-radius: 7px;
}

.carousel-control-prev-icon{
  background-color: black;
}

.carousel-control-prev,
.carousel-control-next {
position: absolute;
bottom: 20px; /* Move the buttons to the bottom */
left: auto;
right: auto;
width: 80px; /* Adjust the size of the buttons */
height: 50px;
border-radius: 50%; /* Make them circular */
top: 92%;
display: flex;
justify-content: center;
align-items: center;
color: white; /* Arrow color */
opacity: 0.8; /* Slight transparency */
transition: transform 0.3s ease, opacity 0.3s ease;

}

.carousel-control-prev:hover,
.carousel-control-next:hover {
opacity: 1; /* Fully visible on hover */
transform: scale(1.1); /* Slightly larger on hover */
}

.carousel-control-prev {
left: calc(10% - 100px); /* Adjust position to the left side */
}

.carousel-control-next {
  left: calc(10%); /* Adjust position to the right side */
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
background-color: transparent; /* Remove default background */
background-size: contain; /* Ensure proper sizing */
filter: invert(1); /* Make the arrow icon visible on dark background */
}


/* .mb-homebg{
height: 70vh;
background-image: url('../../assests/home/MB BANNR.avif');
background-position: center;
background-size: cover;
margin-top: -10px;
overflow: hidden;
position: relative; 
padding: 25px;
color: white;

} */

/* .mb-homebg::after {
content: '';
height: 70vh;
position: absolute;
bottom: 0;
left: 0;
right: 0;
top: 0;
background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.2) 60%, rgba(0, 0, 0, 0) 100%);
pointer-events: none; 
z-index: 1;
} */

.mbbannerimg{
width: 100%;
height: 400px;
background-repeat: no-repeat;
background-position: center;
background-size:contain;
}

.mb-homebg h1, .mb-homebg h4 {
position: relative; 
z-index: 2; 
}

.mb-homebg h1{
font-size: 35px;
}


#carouselExampleAutoplaying{
margin-top: 60px;
margin-bottom: 80px;
}


.webcontenthomepage{
background-color: rgb(240,240,240);
margin-top: 50px;
padding: 50px 0px;
text-align: justify; /* Distribute text evenly across the width */
line-height: 1.6; /* Adjust line height for better readability */
word-break: break-word; /* Prevent words from overflowing */
hyphens: auto; /* Enable word hyphenation for better justification */
}


.webcontenthomepage p {
font-size: 12px;
color: rgb(105, 105, 105);
margin-bottom: 5px;
}

.webcontenthomepage h1{
font-size: 25px !important;
font-weight: 600;
}

.webcontenthomepage h2{
font-size: 20px;
font-weight: 600;
}

.webcontenthomepage h3{
font-size: 20px;
font-weight: 600;
}

.webcontenthomepage h4{
font-size: 18px;
font-weight: 600;
}

.homelocationsbtn{
  height: 175px;
  width: 160px;
}


@media (max-width: 768px) {
.webcontenthomepage{
  background-color: rgb(240,240,240);
  margin-top: 30px;
  padding: 30px 10px;
  text-align: left; /* Distribute text evenly across the width */
}



.webcontenthomepage p {
  font-size: 12px;
  color: rgb(105, 105, 105);
}

.webcontenthomepage h2{
  font-size: 18px;
  font-weight: 600;
}

.webcontenthomepage h3{
  font-size: 16px;
  font-weight: 600;
}
}















.carosel-container {
  height: auto; /* Allow it to adjust dynamically */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 50px;
}

.carosele-image {
  height: 440px; /* Maintain aspect ratio */
  max-width: 100%; /* Ensure it doesn't overflow */
  border-radius: 20px;
  object-fit: cover; /* Ensure proper cropping */
  max-height: 400px;
}

.dotspic {
  position: absolute;
  z-index: -3;
  margin-top: -50px;
  height: 150px;
  margin-left: -90px;
  opacity: 0.3;
}

.carosel-container h5 {
  font-size: 55px;
  line-height: 1.2;
  font-weight: 550;
}

.carosel-container h2 {
  font-size: 25px;
  font-weight: 400;
  color: gray;
  line-height: 1.5;
}

.explorebtn, .enquirebtn {
  padding: 10px 20px;
  font-size: 14px;
  margin-right: 10px;
}

.carousel-control-prev,
.carousel-control-next {
  bottom: 10px; /* Adjust button placement */
  transform: scale(0.8); /* Reduce size for smaller screens */
}

.carousel-control-prev:hover,
.carousel-control-next:hover {
  transform: scale(1); /* Restore original size on hover */
}

@media (max-width: 768px) {
  .carosel-container {
      flex-direction: column; /* Stack text and image */
      text-align: center; /* Center-align text */
      padding: 10px;
  }

  .carosel-container h1 {
      font-size: 30px; /* Reduce font size */
  }

  .carosel-container h2 {
      font-size: 18px; /* Reduce font size */
  }

  .carosele-image {
      width: 100%; /* Take full width */
      height: auto; /* Maintain aspect ratio */
  }

  .explorebtn, .enquirebtn {
      width: 100%; /* Buttons take full width */
      margin: 10px 0;
      font-size: 14px;
  }

  .sharkbannerimg{
    border-radius: 0px;
  }

  .carousel-control-prev,
  .carousel-control-next {
      width: 40px; /* Reduce size of controls */
      height: 40px;
      bottom: 5%; /* Adjust position */
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
      background-size: 60%; /* Scale down icons */
  }
}

.carousel-track {
display: flex;
width: 300%; /* (100% * number of slides) */
transition: transform 0.6s ease-in-out; /* Smooth transition */
}

/* .readourstorybtnlp{
position: absolute;
bottom: 87px;
right: 169px;
background-color: transparent;
border-radius: 7px;
padding: 3px 15px;
} */

.readourstorybtnmb {
background-color: transparent;
border: 1px solid #000;
border-radius: 7px;
padding: 3px 10px;
font-size: 12px;
transition: all 0.3s ease-in-out;
background-position: center;
background-size: cover;
}

.mbbtnbanmnerbox{
height: 100%;
}

.readourstorybtnlp {
  position: absolute;
  bottom: 19%;
  left: 5%;
  background-color: transparent;
  border: 1.8px solid #ffffff;
  border-radius: 7px;
  padding: 10px 20px !important;
  font-size: 15px;
  transition: all 0.3s ease-in-out;
  }


  .bannervideo{
    border-radius: 20px;
    height: 400px;
    overflow: hidden;
    width: 50%;
  }

  .bannervideo video{
    margin-bottom: -8px;
    object-fit: cover;
    object-position: center;
  }

/* Medium screens like tablets */
@media (max-width: 1024px) {

  .bannervideo{
    border-radius: 0px;
    height: 400px;
    overflow: hidden;
    width: 100%;
    margin-top: 30px;
  }
.readourstorybtnlp {
  bottom: 8%;
  left: 8%;
  padding: 8px 16px;
  font-size: 0.9rem;
}
#new-cards-section {
  min-height: 600px; /* Adjust based on the expected height */
}
}


/* Medium screens like tablets */
@media (max-width: 1200px) {
.readourstorybtnlp {
  bottom: 18%;
  left: 5%;
  padding: 2px 10px;
  font-size: 12px;
}
}
