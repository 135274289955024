.cardvo {
    border: 2px solid rgb(241, 241, 241);
    border-radius: 12px;
    transition: transform 0.2s;
    margin-bottom: 80px;
    color: rgb(120, 120, 120);
    width: 400px;
  }

  .cardvo button{
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.049) !important;
font-size: 15px;
  }
  
  .cardvo:hover {
    transform: translateY(-5px);
  }

  .list-unstyled{
    height: 180px;
    font-size: 15px;
  }

  .voheaders{
    background-color: rgb(241, 241, 241);
    padding: 9px;
    text-align: center;
  }

  .voheaders h2{
    font-weight: 400;
    font-size: 1.25rem;
  }

  .shadow-smm {
    border: 1px solid rgba(128, 128, 128, 0.318);
    box-shadow:  2px 4px rgba(0, 0, 0, 0.1);
  }
  
 .redeem .text-center {
    text-align: center;
  }
  
  .redeem .rounded {
    border-radius: 12px;
  }
  
  .redeem p {
    color: rgb(120, 120, 120);
    font-size: 15px;
  }
  
  .redeem h6 {
    color: rgb(51, 51, 51);
    font-size: 16px;
  }
  
  .redeem strong {
    font-weight: bold;
  }

  .benefits {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    margin-top: 40px;
    max-width: 900px;
    margin: auto;
    width: 100%;
  }
  
  .benefit-card-vo {
    background-color: #ffffff;
    border: 1px solid #f1f1f1;
    border-radius: 12px;
    padding: 15px;
    flex: 1;
    margin-bottom: 20px;
    max-width: 500px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  .benefit-card-vo:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  }
  
  .benefit-card-vo h3 {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 10px;
  }
  
  .benefit-card-vo p {
    font-size: 14px;
    color: #787878;
    line-height: 1.6;
  }

  .ourvosection{
    background-color: #cdcccc70;
    padding: 45px;
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
  }

  .ourvosection h2{
    text-align: center;
    font-weight: 400;
  }

  .custvobox{
    height: auto !important;
    max-width: 350px;
  }





  .how-it-works {
    padding: 40px 20px;
    max-width: 1300px;
    margin: 0 auto;
  }
  
  .how-it-works h1 {
    font-size: 35px;
    color: #333;
    margin-bottom: 30px;
    font-weight: 500;
  }
  
  .steps-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
  }
  
  .step {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 10px 20px;
    position: relative;
  }
  
  .step:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 20%;
    right: -20px;
    width: 150px;
    height: 2px;
    background-color: #b3b3b3;
    transform: translateY(-50%);
  }
  
  .step .icon {
    background-color: #fdf1f1;
    border: 1px solid #f1f1f1;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
  

  
  .step p {
    font-size: 16px;
    color: #666;
    margin: 0;
    margin-top: 30px;
    height: 40px;
  }

  .webcontenthomepage{
    background-color: rgb(240,240,240);
    margin-top: 50px;
    padding: 50px 0px;
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
  }

  .webcontenthomepage h1{
    font-size: 25px;
    font-weight: 600;
  }
  
  
  .webcontenthomepage p {
    font-size: 12px;
    color: rgb(105, 105, 105);
    margin-bottom: 5px;
  }
  
  .webcontenthomepage h2{
    font-size: 20px;
    font-weight: 600;
  }
  
  .webcontenthomepage h3{
    font-size: 18px;
    font-weight: 600;
  }

  .webcontenthomepage ol li{
    font-size: 12px;
    color: rgb(105, 105, 105);
  }
  
  
  @media (max-width: 768px) {


    .how-it-works {
      padding: 20px 10px;
      max-width: 1300px;
      width: 90vw;
      margin: 0 auto;
    }
    
    .how-it-works h1 {
      font-size: 32px;
      color: #333;
      margin-bottom: 30px;
      font-weight: 500;
    }
    
    .steps-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 15px;
      margin-top: 20px;
    }
    
    .step {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: start;
      padding: 10px 0px;
      position: relative;
    }
    
    .step:not(:last-child)::after {
      content: '';
      position: absolute;
      top: 20%;
      right: -20px;
      width: 50px;
      height: 2px;
      background-color: #b3b3b3;
      transform: translateY(-50%);
    }
    
    .step .icon {
      background-color: #fdf1f1;
      border: 1px solid #f1f1f1;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
    }
    
    .step .icon img{
      height: 100%;
      width: 100%;
    }
  
    
    .step p {
      font-size: 10px;
      color: #666;
      margin: 0;
      margin-top: 10px;
      height: 50px;
    }
  
  
  
 
  }
  
  
  
  @media (max-width: 768px) {
    .benefits {
      flex-direction: column;
      align-items: center;
    }
  
    .benefit-card-vo {
      max-width: 100%;
    }
  }
  
  

  @media (max-width: 768px) {
    .cardvo {
        border: 2px solid rgb(241, 241, 241);
        border-radius: 12px;
        transition: transform 0.2s;
        margin-bottom: 20px;
        color: rgb(120, 120, 120);
        width: 93vw;
      }
  }


  
  @media (max-width: 768px) {
    .webcontenthomepage{
      background-color: rgb(240,240,240);
      margin-top: 30px;
      padding: 30px 10px;
    }
    
    
    .webcontenthomepage p {
      font-size: 12px;
      color: rgb(105, 105, 105);
    }
    
    .webcontenthomepage h2{
      font-size: 18px;
      font-weight: 600;
    }
    
    .webcontenthomepage h3{
      font-size: 16px;
      font-weight: 600;
    }
  }