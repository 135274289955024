.stands-out-section {
  padding: 2rem;
  text-align: center;
}

.stands-out-section h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 1.5rem;
}

.channel-benefits-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Adjusted for better responsiveness */
  gap: 1rem;
  margin-top: 1rem;
  width: 100%;
}

.channel-benefit-card {
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 0.9rem;
  text-align: center;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  width: 230px;
  margin: auto;
  height: 280px;
}

.channel-benefit-card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transform: translateY(-5px);
}

.channelcardbtn{
  margin-top: 10px;
  border: 1px solid #e15252;
  padding: 5px;
  width:  100%;
  border-radius: 10px;
  background-color: transparent;
  color: #e15252;
}

.channelcardbtn:hover{
  background-color: #e15252;
  color: white;
}

.channel-benefit-card .channel-icon {
  height: 150px;
  width: 200px;
  color: #e15252;
  margin-bottom: 1rem;
  border-radius: 10px;
}

.channel-benefit-card h3 {
  font-size: 19px;
  color: #333;
  margin-bottom: 0.5rem;
}

.channel-benefit-card p {
  font-size: 0.9rem;
  color: #555;
  line-height: 1.5;
}

/* Media Query for Tablets and Smaller Devices */
@media screen and (max-width: 768px) {
  .stands-out-section {
    padding: 1.5rem;
  }

  .channel-benefits-grid {
    grid-template-columns: 1fr; /* Display one card per row */
    gap: 1rem; /* Reduce gap for smaller screens */
  }

  .channel-benefit-card {
    padding: 1rem;
  }

  .channel-benefit-card {
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 0.9rem;
    text-align: center;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    width: 80%;
    margin: auto;
    height: 280px;
  }

  .channel-benefit-card .icon {
    height: 150px;
    width: 250px;
    color: #e15252;
    margin-bottom: 1rem;
    border-radius: 10px;
  }
}

/* Media Query for Small Screens (e.g., phones) */
@media screen and (max-width: 480px) {
  .stands-out-section h2 {
    font-size: 1.5rem;
  }

  .channel-benefit-card {
    padding: 0.8rem;
  }

  .channel-benefit-card .icon {
    font-size: 1.75rem;
  }

  .channel-benefit-card h3 {
    font-size: 1rem;
  }

  .channel-benefit-card p {
    font-size: 0.8rem;
  }
}
