.stickybar-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgb(227,215,203); /* Light blue background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 500;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .stickybar-content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
    width: 100%;
    max-width: 1200px;
    position: relative;
  }
  
  .stickybar-text {
    color: rgb(44, 44, 44);
    font-size: 18px;
    margin-right: 50px;
  }
  

  
  .stickybar-close {
    background: none;
    border: none;
    color: #e15252;
    font-size: 18px;
    cursor: pointer;
    font-weight: 600;
    margin-left: 15px;
  }
  
  .stickybar-close:hover {
    color: #ff4d4d;
  }


  .expaltf{
    background-color: white;
    padding: 5px 30px;
    margin-right: 30px;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
  }
  