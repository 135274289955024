.slide2img2{
    height: 230px;
    width: 400px;
    
}

.slide2img1{
    height: 230px;
    width: 400px;
}


.slide2img3{
    height: 120px;
    width: 180px;
}

.slide2img4{
    height: 230px;
    width: 290px;
}


.mbslide2heading{
    height: 120px;
    width: 70vw;
    background-image: url('../assests/slide2year.avif');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}


.mbslide2heading h1{
    font-size: 32px;
    font-weight: 400;
}

.mbslide3heading{
    background-image: url('../assests/slide1.avif');
}

.mbslide3heading h1{
    font-size: 40px;
}

.slide3mbpic{
    width: 80vw;

}


/* @media (300px <= width <= 700px ) {
   
    .slide2img1{
        height: 230px;
        width: 400px;
    }

    .whysmallpic{
        height: 300px;
        border-radius: 10px;
    }
} */


