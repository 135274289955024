.blog-container {
    margin-top: 130px;
  }
  
  .blog-item {
    margin-bottom: 20px;
    border-radius: 8px;
    /* border: 1px solid #ddd; */
  }
  
  .blog-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .blog-summary {
    font-size: 1rem;
    color: #555;
  }
  
  .blog-meta {
    font-size: 0.875rem;
    color: #888;
    margin-top: 10px;
  }
  

  
  .blog-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 10px;
  }

  .blog-image-latest-home{
    width: 100%;
    height: 200px;
    border-radius: 8px;
  }
  
  .blog-content {
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .blog-item-left-image-right-content {
    display: flex;
    margin-bottom: 10px;
  }
  
  .blog-item-left-image-right-content .col-md-4 {
    padding-right: 15px;
  }
  
  .blog-item-left-image-right-content .col-md-8 {
    padding-left: 15px;
  }

  .blog-rightbox-title{
    font-size: 18px;
  }

  .blog-rightbox-summary{
    font-size: 15px;
  }

  .blog-rightbox-meta{
    margin-bottom: 2px;
  }

  .blog-rightbox-content{
    padding: 2px;
  }

/* Skeleton Loader Base */
.skeleton-card {
  width: 100%;
  height: 200px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 400% 100%;
  animation: shimmer 1.5s infinite linear;
  border-radius: 10px;
  margin-bottom: 20px;
}

/* Larger Skeleton Loader for Featured Blog */
.skeleton-card.large {
  height: 300px;
}

.right-blog-small-img{
  height: 80%;
  width: 300px;
  object-fit: cover;
}

/* Shimmer Effect */
@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

  
  @media (max-width: 768px) {
    .blog-container .row {
      flex-direction: column;
    }

    .right-blog-small-img{
      height: 200px;
      width: 100%;
      object-fit: cover;
    }
  
    .blog-right .row {
      flex-direction: column;
    }
  
    .blog-item {
      margin-bottom: 15px;
    }
  
    .blog-container {
      margin-top: 10px;
    }
  
    .col-md-12 {
      margin-bottom: 15px;
    }
  
    .blog-item-left-image-right-content {
      flex-direction: column;
    }
  
    .blog-item-left-image-right-content .col-md-4 {
      padding-right: 0;
    }
  
    .blog-item-left-image-right-content .col-md-8 {
      padding-left: 0;
    }

    .skeleton-card {
      height: 150px;
    }
  }
