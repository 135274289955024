/* quiz.css */

/* Overlay and modal container */
.quiz-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .quiz-modal {
    background: #F8F8F8;
    color: #e15252;
    width: 90%;
    max-width: 600px;
    padding: 40px;
    padding-top: 45px;
    border-radius: 46px;
    position: relative;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    animation: fadeIn 0.5s ease-in-out;
    text-align: left; /* Enhanced text alignment */
    display: flex;
    flex-direction: column;
  }

  .quiz-form-container {
    max-height: 50vh;       /* so the form won't overflow beyond the viewport */
    overflow-y: auto;       /* enable vertical scroll only here */
    margin-top: 10px;       /* optional spacing */
  }
  
  /* Optional custom scrollbar for the form container */
  .quiz-form-container::-webkit-scrollbar {
    width: 8px;
  }
  
  .quiz-form-container::-webkit-scrollbar-track {
    background: #F8F8F8;
    border-radius: 46px;
  }
  
  .quiz-form-container::-webkit-scrollbar-thumb {
    background: #e15252;
    border-radius: 46px;
  }
  .quiz-form-container::-webkit-scrollbar-thumb:hover {
    background: #c84242;
  }
  
  /* For Firefox */
  .quiz-form-container {
    scrollbar-width: thin;
    scrollbar-color: #e15252 #F8F8F8;
  }
  
  
  
  
  /* Fade in animation */
  @keyframes fadeIn {
    from { opacity: 0; transform: scale(0.95); }
    to { opacity: 1; transform: scale(1); }
  }
  
  /* Interactive Progress Bar */
  .quiz-progress-container {
    margin-top: 20px;
  }
  
  .quiz-progress-line {
    width: 100%;
    height: 1px;
    background: #e15252;
    position: relative;
    border-radius: 2px;
    overflow: hidden;
  }
  
  .quiz-progress-filled {
    height: 100%;
    background: #e15252;
    width: 0;
    transition: width 0.3s ease;
  }
  
  .quiz-progress-dots {
    display: flex;
    justify-content: space-between;
    margin-top: -12px;
    position: relative;
    z-index: 1;
    margin-bottom: 35px;
  }
  
  .quiz-progress-dot {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #232323;
    border: 2px solid rgb(33, 32, 32);
    transition: background 0.3s, border 0.3s;
    cursor: pointer;
    color: rgb(255, 255, 255);
    font-size: 12px;
    padding-top: 3px;
  }
  
  .quiz-progress-dot.active {
    background: #e15252;
    border-color: #e15252;
    color: white;
  }
  
  /* Step container */
  .quiz-step {
    margin-top: 10px;
  }
  
  /* Headers and progress text */
  .quiz-header {
    font-size: 1.5rem;
    margin-bottom: 15px;
    text-align: center;
  }
  
  .quiz-progress-text {
    font-size: 0.9rem;
    margin-bottom: 15px;
  }
  
  /* Question text */
  .quiz-question {
    font-size: 1.2rem;
    margin-bottom: 20px;
    text-align: center;
    letter-spacing: 1px;
    background-color: #e15252;
    color: white;
    padding-top: 8px;
    border-radius: 6px;
    padding-left: 10px;
    padding-right: 10px;
  }
  
  /* Options */
  .quiz-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  
  .quiz-option {
    display: flex;
    align-items: center;
    gap: 10px;
    border: 1px solid #252424;
    border-radius: 10px;
    padding: 6px;
    cursor: pointer;
    transition: background 0.3s;
    font-size: 16px;
    width: 75%;
  }

  .quiz-option-label{
    text-align: center;
    width: 100%;
    padding-right: 5px;
  line-height: 16px;
  }
  
  .quiz-option:hover {
    background: #eee;
  }
  
  .quiz-option.selected {
    background: #e15252;
    border-color: #e15252;
  }
  
  .quiz-option-icon {
    font-size: 1rem;
  }
  
  /* Buttons */
  .quiz-next-btn,
  .quiz-submit-btn {
    background: #2a2929;
    color: white;
    border: none;
    padding: 1px 20px;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
    width: auto;
    transition: background 0.3s;
    margin-top: 10px;
    padding-top: 2px;
  }
  
  .quiz-next-btn:disabled {
    cursor: not-allowed;
  }
  
  .quiz-next-btn:hover:not(:disabled),
  .quiz-submit-btn:hover {
    background: #c84242;
  }
  
  /* Form */
  .quiz-form {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .quiz-input,
  .quiz-select {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    width: 100%;
  }
  
  /* Final Result Popup */
  .quiz-result-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #F8F8F8;
    padding: 30px 20px;
    border-radius: 8px;
    max-width: 600px;
    width: 90%;
    z-index: 1100;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    text-align: left;
    color: #252424;
  }
  
  .quiz-result-content {
    position: relative;
    z-index: 2;
    text-align: center;
  }
  
  .quiz-result-icon {
    font-size: 3rem;
    margin-bottom: 10px;
  }
  
  .quiz-result-heading {
    font-size: 2.2rem;
    margin-bottom: 10px;
    
  }
  
  .quiz-result-message {
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .quiz-result-additional {
    font-size: 0.95rem;
    margin-bottom: 20px;
  }
  
  .quiz-result-btn {
    background: #e15252;
    border: none;
    color: white;
    padding: 12px 20px;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s;
    width: 70%;
  }
  
  .quiz-result-btn:hover {
    background: #c84242;
  }
  
  /* Close button for result popup */
  .quiz-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    transition: color 0.3s;
  }

  .closeicon-quiz{
    color: rgb(26, 26, 26);
  }
  

  
  /* Confetti animation */
  .quiz-confetti {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
  }
  
  .confetti-piece {
    position: absolute;
    width: 10px;
    height: 10px;
    background: #e15252;
    opacity: 0.8;
    animation: fall 3s linear infinite;
  }

  .quiz-navigation{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }


  
  @keyframes fall {
    0% {
      transform: translateY(-10px) rotate(0deg);
      opacity: 1;
    }
    100% {
      transform: translateY(110vh) rotate(360deg);
      opacity: 0;
    }
  }
  
  /* Responsive adjustments */
  @media (max-width: 600px) {
    .quiz-modal {
      width: 95%;
      padding: 15px;
    }
    .quiz-header {
      font-size: 1.3rem;
    }
    .quiz-question {
      font-size: 1rem;
    }
    .quiz-next-btn,
    .quiz-submit-btn,
    .quiz-result-btn {
      font-size: 0.95rem;
      padding: 10px;
    }
    .quiz-option {
     
      width: 100%;
    }
    .quiz-form {
      gap: 5px;
    }


    .quiz-input,
    .quiz-select {
      padding: 7px;
      border: 1px solid #ddd;
      border-radius: 5px;
      font-size: 1rem;
      width: 100%;
    }
  }
  