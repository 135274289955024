@font-face {
    font-family: "Founders Grotesk medium"; 
    src: url("../assests/FoundersGrotesk-Medium.otf") format("opentype");
    font-style: normal;
  }

  @font-face {
    font-family: "Founders Grotesk Regular"; 
    src: url("../assests/FoundersGrotesk-Regular.otf") format("opentype");
  }

  @font-face {
    font-family: "Founders Grotesk light"; 
    src: url("../assests/FoundersGrotesk-Light.otf") format("opentype");
  }

  @font-face {
    font-family: "Founders Grotesk bold"; 
    src: url("../assests/FoundersGrotesk-Bold.otf") format("opentype");
  }




.visitingcardcontainer {
    background-color: #2d2e2e;
    width: 100%;
    max-width: 1000px;
    height: 97vh;
    color: white;
    position: relative; 
    overflow: hidden;
    border: 1px solid white;
    border-radius: 20px;
  }
  
  .logo {
    height: 150px;
    padding-right: 30px;
  }
  
  .hrline {
    height: 2px;
    background-color: white;
    width: 90%;
    margin: 10px 0px;
  }
  
  .iconcontaioner {
    width: 90%;
    font-size: 40px;
    margin-top: 10px;
    margin-bottom: 30px;
  }
  
  .detailscontainer {
    height: 60%;
    position: relative;
    background-image: url('../assests/wdefgge.png'); /* Update the path as needed */
    background-repeat: no-repeat;
    background-position: right bottom; /* Aligns the image to the right */
    background-size: 170px auto; /* Adjust size as needed */
    padding: 20px;
  }
  
  .bgcontainer {
    display: none; /* Remove the previous explicit image container */
  }
  

  .detailscontainer h1{
    font-size: 80px;
    font-family: "Founders Grotesk Regular"; 
  }

  .detailscontainer h2{
    font-size: 60px;
    font-family: "Founders Grotesk light"; 
  }

  .detailscontainer h3{
    font-size: 40px;
    font-family: "Founders Grotesk light"; 
  }


  .mbdetailscontainer{
    height: 100%;
  }

  .visitingcardcontainer a{
    text-decoration: none;
    color: inherit;
    font-size: 30px;
  }

  .justifycontentmd{
    justify-content: center;
  }

  .icon{
    height: 40px;
  }


  @media (200px <= width <= 800px){
    .icon{
      height: 35px;
    }

    .logo {
        height: 90px;
        padding-right: 20px;
      }


      .justifycontentmd{
        justify-content: start;
      }
      
    .visitingcardcontainer {
        background-color: #2d2e2e;
        width: 100vw;
        height: 95vh;
        color: white;
        position: relative; 
        overflow: hidden;
        border: none;
        border-radius: 0px;
      }

      .detailscontainer h1{
        font-size: 48px;
        font-family: "Founders Grotesk Regular"; 
        width: 80%;
      }
    
      .detailscontainer h2{
        font-size: 40px;
        font-family: "Founders Grotesk light"; 
      }
    
      .detailscontainer h3{
        font-size: 28px;
        font-family: "Founders Grotesk light";     
      }


    
      .gmail{
        font-size: 25px !important;
      }

      .detailscontainer {
        height: 65%;
        position: relative;
        background-image: url('../assests/wdefgge.png'); 
        background-repeat: no-repeat;
        background-position: right bottom; 
        background-size: 150px auto; 
        padding: 20px;
      }
      
    .iconcontainer{
      width: 90% !important;
    }
  }