.landlord-container {
    position: relative;
    width: 90%;
    height: 500px; /* Full screen height */
    background: url('../../../assests/footerpages/businnesspartner/landlords.avif') no-repeat center center/cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    text-align: left;
    color: white;
    background-position: center;
    background-size: cover;
  }
  
  .landlord-overlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.699); /* Dark overlay for readability */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 50px;
  }
  
  .landlord-content {
    max-width: 800px;
  }
  
  .landlord-heading {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .landlord-text {
    font-size: 1.7rem;
    margin-bottom: 20px;
  }
  
  .landlord-cta-button {
    padding: 8px 65px;
    font-size: 18px;
    font-weight: bold;
    background: #101010;
    color: white;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-bottom: 20px;
    border: none;
  }
  
  .landlord-cta-button:hover {
    background: white;
    color: black;
  }

  .bottom-lanloard-btn{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }


  .landlord-info-section {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 30px;
    margin: auto;
    gap: 50px;
  }
  
  .landlord-info-content {
    flex: 1;
    padding-right: 40px;
    
  }

  .landlordicons{
    font-size: 40px;
    width: 100%;
    margin-bottom: 15px;
    color: #ffffff;
  }
  
  .landlord-info-section h4{
    margin-bottom: 10px;
  }

  
  .landlord-info-text {
    font-size: 18px;
    line-height: 1.6;
    color: #ffffff;
    text-align: center;
  }
  
  .landlord-info-image-wrapper {
    flex: 1;
  }


  .expapantionbtn{
    margin-top: -10px;
    border: none;
    border-radius: 20px;
    padding: 10px 30px;
    background-color: #e15252;
    color: white;
  }
  

  .landlord-faq-section {
    background: rgb(34, 34, 34);
    padding: 20px;
    border-radius: 20px;
    color: white;
    margin: 10px auto;
    text-align: left;
    height: 150px;
    width: 300px;

  }

  .mb-button-landlord{
    margin: auto;
    margin-top: 20px;
  }
  


.mbbannerlord{
  height: 100%;
  width: 100%;
}
  
  
  @media (max-width: 768px) {
    .landlord-heading {
      font-size: 2.5rem;
    }
  
    .landlord-text {
      font-size: 1rem;
    }
  
    .landlord-info-heading {
      font-size: 2rem;
    }
  
    .landlord-info-text {
      font-size: 1rem;
    }
  
    .landlord-cta-button {
      font-size: 0.9rem;
      padding: 10px 20px;
    }
    .landlord-info-section {
       flex-direction: column;
       padding: 20px;
       padding-top: 0px;
       gap: 0px;
      }
      .landlordsecondsection{
        text-align: center;
        margin-top: 10px;
        padding: 5px;
      }

      .landlordicons{
        font-size: 30px;
       
      }

      .landlordeasy{
        margin-top: 15px;
      }
      .landlordsecondsection h2{
        font-size: 25px;
        margin-top: 10px;
      }
   
  }
  
  @media (max-width: 480px) {
    
  
    .landlord-heading {
      font-size: 2rem;
    }
  
    .landlord-text {
      font-size: 0.9rem;
    }
  
    .landlord-info-heading {
      font-size: 1.8rem;
    }
  
    .landlord-info-text {
      font-size: 13px;
    }
  
    .landlord-cta-button {
      font-size: 0.8rem;
      padding: 8px 18px;
      margin-bottom: 0px;
    }
  }




/* Ensure full viewport width on mobile */
@media (max-width: 768px) {
    .landlord-container {
        width: 100vw; /* Full viewport width */
        height: 300px; /* Full viewport height */
        background-size: contain; /* Ensures entire image is visible */
        background-repeat: no-repeat; /* Prevents repeating */
    }
}

