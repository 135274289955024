.channel-partners-container {
    position: relative;
    width: 100%;
    height: 70vh; /* Full screen height */
    background: url('../../../assests/property-detail/noida/altf-noida-58/5.avif') no-repeat center center/cover;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    color: white;
  }
  
  .channel-partners-overlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.699); /* Dark overlay for readability */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 50px;
  }
  
  .channel-partners-content {
    max-width: 800px;
  }
  
  .channel-partners-heading {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .channel-partners-text {
    font-size: 21px;
    margin-bottom: 20px;
  }
  
  .channel-partners-cta-button {
    padding: 12px 25px;
    font-size: 1rem;
    font-weight: bold;
    border: 1px solid white;
    background: #e15252;
    color: white;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .channel-partners-cta-button:hover {
    background: white;
    color: black;
  }

  .bottom-lanloard-btn{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }


  .channel-partners-info-section {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 30px;
    margin: auto;
    gap: 50px;
  }
  
  .channel-partners-info-content {
    flex: 1;
    padding-right: 40px;
    
  }

  .channel-partnersicons{
    font-size: 40px;
    width: 100%;
    margin-bottom: 15px;
    color: #ffffff;
  }
  
  .channel-partners-info-section h4{
    margin-bottom: 10px;
  }

  
  .channel-partners-info-text {
    font-size: 16px;
    line-height: 1.6;
    text-align: center;
  }
  
  .channel-partners-info-image-wrapper {
    flex: 1;
  }


  .expapantionbtn{
    margin-top: 30px;
    border: none;
    border-radius: 20px;
    padding: 10px 30px;
    background-color: #e15252;
    color: white;
  }
  

  .channel-partners-faq-section {
    background: rgba(229, 229, 229, 0.495);
    padding: 20px;
    border-radius: 20px;
    color: rgb(0, 0, 0);
    margin: 20px auto;
    text-align: center;
    height: 200px;
    width: 250px;
    box-shadow: 3px 5px 8px 0px rgba(0, 0, 0, 0.2);
  }




  .banner-channel{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
    background-color: #e15252;
    color: white;
    border-radius: 10px;
  }

  .banner-channel h5{
    font-weight: 300;
  }

  .channelexplorebtn{
    background-color: white;
    color: black;
    font-weight: 500;
  }
  

  

  .channel-partnerssecondsection{
    text-align: center;
    margin-top: 30px;
    padding: 20px;
  }

  .channel-partnerssecondsection h2{
    font-size: 35px;
  }


  .channel-partners-form-control {
    margin: 20px auto; /* Center the form */
    background-color: #ffffff;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6);
    width: 400px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    padding: 25px;
    border-radius: 8px;
    color: #000000;
}

/* Ensure form takes full width on smaller screens */
@media (max-width: 768px) {
    .channel-partners-form-control {
        width: 90%; /* Make it fit smaller screens */
        padding: 20px; /* Reduce padding */
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5); /* Lighter shadow */
    }
    .channel-partners-overlay {
      padding: 20px;
    }


    .banner-channel{
    margin: 10px;
    }
  
    .banner-channel h5{
      font-weight: 300;
      font-size: 15px;
    }
}

@media (max-width: 480px) {
    .channel-partners-form-control {
        width: 95%; /* Almost full width */
        padding: 15px;
    }
}

.channel-partners-title {
    font-size: 28px;
    font-weight: 800;
    text-align: center; /* Center the title */
}

.channel-partners-input-field {
    position: relative;
    width: 100%;
}

.channel-partners-input {
    margin-top: 15px;
    width: 100%;
    outline: none;
    border-radius: 8px;
    height: 45px;
    border: 1.5px solid #ecedec;
    background: transparent;
    padding-left: 10px;
    font-size: 16px;
}

/* Adjust input fields on smaller screens */
@media (max-width: 480px) {
    .channel-partners-input {
        height: 40px;
        font-size: 14px; /* Smaller text */
    }
}

.channel-partners-input:focus {
    border: 1.5px solid #000000;
}

.channel-partners-input-field .channel-partners-label {
    position: absolute;
    top: 25px;
    left: 15px;
    color: #000000 !important;
    transition: all 0.3s ease;
    pointer-events: none;
    z-index: 2;
}

/* Adjust label position for better readability */
.channel-partners-input-field .channel-partners-input:focus ~ .channel-partners-label,
.channel-partners-input-field .channel-partners-input:valid ~ .channel-partners-label {
    top: 5px;
    left: 5px;
    font-size: 12px;
    color: #2d79f3;
    background-color: #ffffff;
    padding-left: 5px;
    padding-right: 5px;
}

/* Submit Button */
.channel-partners-submit-btn {
    margin-top: 30px;
    height: 55px;
    background: #f2f2f2;
    border-radius: 11px;
    border: 0;
    outline: none;
    color: #ffffff;
    font-size: 18px;
    font-weight: 700;
    background: linear-gradient(180deg, #363636 0%, #1b1b1b 50%, #000000 100%);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
    cursor: pointer;
}

/* Make button size smaller on mobile */
@media (max-width: 480px) {
    .channel-partners-submit-btn {
        height: 45px;
        font-size: 16px;
    }
}

.channel-partners-submit-btn:hover {
    box-shadow: 0px 0px 0px 2px #ffffff, 0px 0px 0px 4px #0000003a;
}

  
  

  
  
  @media (max-width: 768px) {
    .channel-partners-heading {
      font-size: 2.5rem;
    }
  
    .channel-partners-text {
      font-size: 1rem;
    }
  
    .channel-partners-info-heading {
      font-size: 2rem;
    }
  
    .channel-partners-info-text {
      font-size: 1rem;
    }
  
    .channel-partners-cta-button {
      font-size: 0.9rem;
      padding: 10px 20px;
    }
    .channel-partners-info-section {
       flex-direction: column;
       padding: 20px;
       padding-top: 0px;
       gap: 0px;
      }
      .channel-partnerssecondsection{
        text-align: center;
        margin-top: 10px;
        padding: 5px;
      }
      .channel-partners-faq-section {
        padding: 20px;
      }
      .channel-partnersicons{
        font-size: 30px;
       
      }

      .channel-partnerseasy{
        margin-top: 15px;
      }
      .channel-partnerssecondsection h2{
        font-size: 25px;
        margin-top: 10px;
      }
      .channel-partners-overlay {
        flex-direction: column;
      }

      .channel-partners-container {
        position: relative;
        width: 100%;
        height: auto; /* Full screen height */
        background: url('../../../assests/property-detail/noida/altf-noida-58/1.avif') no-repeat center center/cover;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: left;
        color: white;
      }
  }
  
  @media (max-width: 480px) {
    
  
    .channel-partners-heading {
      font-size: 2rem;
    }
  
    .channel-partners-text {
      font-size: 0.9rem;
    }
  
    .channel-partners-info-heading {
      font-size: 1.8rem;
    }
  
    .channel-partners-info-text {
      font-size: 13px;
    }
  
    .channel-partners-cta-button {
      font-size: 0.8rem;
      padding: 8px 18px;
    }
  }