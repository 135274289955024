.enquiry-container {
    display: flex;
    flex-direction: row;
    font-family: Arial, sans-serif;
    max-width: 1300px;
    margin: 0 auto;
    padding: 20px;
  }

  .enqirylogo{
    height: 70px;
  }

  .closebtnenquire{
    background-color: #e15252;
    border: none;
    color: white;
    border-radius: 15px;
    padding: 5px 12px;
    margin-right: 10px;
  }

  .error-text{
    color: red;
    margin-top: 5px;
  }
  
  .enquiry-image-section {
    flex: 1;
    padding: 20px;
    text-align: center;
    max-width: 40%;
  }
  
  .enquiry-image {
    width: 95%;
    border-radius: 10px;
    height: 580px;
  }
  
  .enquiry-title {
    font-size: 24px;
    margin: 20px 0 10px;
  }
  
  .enquiry-logo {
    font-size: 18px;
    font-weight: bold;
  }
  
  .enquiry-form-section {
    flex: 1;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  .form-heading {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .form-subheading {
    font-size: 16px;
    margin-bottom: 1px;
    margin-top: 10px;
  }
  

  
  input,
  select {
    width: 100%;
    padding: 15px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  input[type="radio"] {
    width: auto;
    margin-right: 10px;
  }
  
  input[type="checkbox"] {
    width: auto;
    margin-right: 10px;
  }
  
  .submit-button {
    background-color: #e63946;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 30px;
  }
  
  .submit-button:hover {
    background-color: #d62828;
  }
  
  .privacy-notice {
    font-size: 15px;
    color: #666;
    margin-top: 10px;
  }

  .formcontainer{
    max-width: 1300px;
    width: 100vw;
  }



  .input-group {
    position: relative;
    margin-bottom: 20px;
  
  }
  
  .input {
    border: solid 1.5px #737373;
    border-radius: 1rem;
    background: none;
    font-size: 1rem;
    color: #333;
    transition: border 150ms cubic-bezier(0.4, 0, 0.2, 1);
    width: 100%;
    border-radius: 0px !important;
  }
  
  .user-label {
    position: absolute;
    left: 15px;
    color: #444444;
    pointer-events: none;
    transform: translateY(1.4rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    background-color: #f4f3f0;
    padding: 0 5px;
  }
  
  .input:focus,
  .input:valid {
    outline: none;
    border: 1.5px solid #1f1f1f;
  }
  
  .input:focus ~ .user-label,
  .input:valid ~ .user-label {
    transform: translateY(-25%) scale(0.8);
    background-color: #f4f3f0;
    padding: 0 0.2em;
  }
  
  .input-section-heading {
    font-size: 30px;
    margin-bottom: 10px;
  }

  .coworkingSpace-dropdown{
    background-color: #f4f3f0;
    border-radius: 10px;
    border: solid 1.5px #9e9e9e;

  }

  
  

  
  .submit-button {
    background-color: #e63946;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .submit-button:hover {
    background-color: #d62828;
  }
  

  

  @media (max-width: 768px) {
    .enquiry-container {
   
        margin-top: 0px;
      }

      .input-section-heading {
        font-size: 20px;
        margin-bottom: 10px;
      }
  }













  